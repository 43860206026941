import { WorkCenter } from "components/workcenters/types";
import { initialValues as WorkCenetInitial } from "../workcenters/types";
import { CommentsType } from "components/Agreements/types";

export const academicStatus = [
  "Activo",
  "Baja temporal",
  "Baja definitiva",
  "Egresado",
];
export const geneders = ["Hombre", "Mujer", "Otro"];
export type hoursMinutes = {
  hours: number,
  minutes: number,
}
export type Student = {
  _id?: string;
  name: string;
  paternalLastname?: string;
  maternalLastname?: string,
  shift?: string;
  email: string;
  curp: string;
  grade?: number;
  career?: string;
  gender?: string;
  phone?: number;
  birthDate?: Date | string;
  tutor: string;
  academicStatus: typeof academicStatus[number];
  completionDate?: Date | string;
  completedHours?: hoursMinutes;
  certificateFileId?: string;
  workcenter: WorkCenter;
  leaveMotive?: string;
  comments?: CommentsType[];
};

export type sendCommentObj = {
  id: string;
  comment: string;
};

export const initialValues: Student = {
  name: "",
  email: "",
  curp: "",
  tutor: "",
  academicStatus: "",
  workcenter: WorkCenetInitial,
};

export const gradesString = [
  " ",
  "PRIMERO",
  "SEGUNDO",
  "TERCERO",
  "CUARTO",
  "QUINTO",
  "SEXTO",
  "SEPTIMO",
  "OCTAVO",
  "NOVENO",
  "DECIMO",
];
export const DescritpionProps = {
  bordered: true,
  column: { xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 },
};