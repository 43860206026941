import { httpApi } from "api/http.api";
import { Agreement, UpdateStatusProps } from "components/Agreements/types";
import { Company } from 'components/companies/types';

const controller = `agreements`;
type Statistics = {
  pending:Agreement[],
  rejected:Agreement[]
}
export type CompanyStatistics = {
  _id: string,
  rejected: Number,
  active: Number,
  pending: Number,
  company: Company,
}
export type SubWorkCStatistics = {
  _id: string,
  rejected: Number,
  active: Number,
  pending: Number,
}
export type UpdateObject = {
  changedValues: Agreement,
  agreement: Agreement
}

export const register = (agreementPayload: Agreement): Promise<Agreement> =>
  httpApi
    .post<Agreement>(`${controller}`, { ...agreementPayload })
    .then(({ data }) => data);

export const list = (filter: string): Promise<{agreements: Agreement[], userRole: number}> =>
    httpApi.get<{agreements: Agreement[], userRole: number}>(`${controller}/${filter}`).then(({ data }) => data);

export const statistics = (): Promise<Statistics> =>
    httpApi.get<Statistics>(`${controller}/statistics`).then(({ data }) => data);

export const companiesStatistics = (): Promise<CompanyStatistics[]> =>
    httpApi.get<CompanyStatistics[]>(`${controller}/companiesStatistics`).then(({ data }) => data);

export const subsystemWorkCStatistics = (): Promise<SubWorkCStatistics[]> =>
    httpApi.get<SubWorkCStatistics[]>(`${controller}/subsystemWorkCStatistics`).then(({ data }) => data);

export const getById = (id: string): Promise<Agreement> =>
    httpApi
      .get<Agreement>(
        `${controller}/one/${id}`
      )
      .then(({ data }) => data);
export const statusUpdate = (
  agreementPayload: UpdateStatusProps
): Promise<{agreement: Agreement, email_status: boolean}> =>
  httpApi
    .patch<{agreement: Agreement, email_status: boolean}>(`${controller}/status`, { ...agreementPayload })
    .then(({ data }) => data);

export const nextApprovalStep = (
  id: string
): Promise<{agreement: Agreement, email_status: boolean}> =>
  httpApi
    .patch<{agreement: Agreement, email_status: boolean}>(`${controller}/nextStep`, { id })
    .then(({ data }) => data);

export const update = (agreementPayload: UpdateObject): Promise<Agreement> =>
  httpApi
    .patch(`${controller}`, { ...agreementPayload })
    .then(({ data }) => data);

export const comment = (
  agreementPayload: UpdateStatusProps
): Promise<Agreement> =>
  httpApi
    .patch<Agreement>(`${controller}/comment`, { ...agreementPayload })
    .then(({ data }) => data);

export const remove = (agreementPayload: Agreement): Promise<Agreement> =>
  httpApi
    .delete(`${controller}`, { data: { id: agreementPayload._id } })
    .then(({ data }) => data);

export const getByCompany = (companyId: string): Promise<Agreement[]> =>
httpApi.get<Agreement[]>(`${controller}/search/companyId/${companyId}`).then(({ data }) => data);

export const getByRep = (representativeId: string): Promise<Agreement[]> =>
httpApi.get<Agreement[]>(`${controller}/search/representativeId/${representativeId}`).then(({ data }) => data);

export const getByStudent = (studentId: string): Promise<Agreement[]> =>
httpApi.get<Agreement[]>(`${controller}/search/studentId/${studentId}`).then(({ data }) => data);
