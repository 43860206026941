import * as S from "./dashboard.styles";
import React, { useEffect, useState } from "react";
import {
  Card as AntCard,
  Col,
  Row,
  Statistic,
  Space,
  Tooltip,
  Empty,
  Layout,
  TourProps,
  } from "antd";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  ClockCircleOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { doListCompStatistics, doListSubWorkCStatistics, doListWorkCStatistics } from "store/slices/statisticsSlice";
import { doList as agreementsDoList } from "store/slices/agreementSlice";
import { doList as companiesDoList } from "store/slices/companySlice";
import { DonutChart } from "./DonutChart";
import { AgreementsList } from "./AgreementsList";
import { CompaniesList } from "./CompaniesList";
import { Agreement } from "components/Agreements/types";
import { Company } from "components/companies/types";
import { ColumnChart, seriesType } from "./ColumnChart";
import { InfoButton } from "components/common/buttons/Button/InfoButton";
import { InfoTour } from "components/common/InfoTour/InfoTour";
import { useTourCheck } from "hooks/useTourCheck";

const { Content } = Layout;

export const DataDisplay = () => {
  const dispatch = useAppDispatch();
  const {
    companiesStatStatus,
    companiesStat,
    subsWorkCStats,
    subsWorkCStatsStatus,
    workCStats,
    workCStatsStatus,
  } = useAppSelector((state) => state.statistics);
  const {
    agreements,
    status: agreementStateStatus,
  } = useAppSelector((state) => state.agreement);
  const {companies, status: companyStateStatus} = useAppSelector((state) => state.company);
  const { user } = useAppSelector((state) => state.user);

  //        Agreements statistics
  // ->donut chart
  const [activeAgreements, setActiveAgreements] = useState<Agreement[]>();
  const [rejectedAgreements, setRejectedAgreements] = useState<Agreement[]>();
  const [pendingAgreements, setPendingdAgreements] = useState<Agreement[]>();
  const [draftAgreements, setDraftAgreements] = useState<Agreement[]>();
  const [seriesDonutChart, setSeriesDonutChart] = useState<number[]>();
  const labelsDonutChart = ["Borrador","Activos", "Pendientes de revision", "Rechazados"];
  const [showDonutChart,setShowshowDonutChart] = useState<boolean>(false);
  // ->agreemnts limited list
  const [agreementsRejectedLimited, setAgreementsRejectedLimited] = useState<Agreement[] | undefined>(undefined);
  const [agreementsPendingLimited, setAgreementsPendingLimited] = useState<Agreement[] | undefined>(undefined);

  //        Company statistics
  // ->company limited list
  const [companiesLimitedList, setCompaniesLimitedList] = useState<Company[] | undefined>(undefined);
  // =>companny column chart
  const [companiesChartSeries, setCompaniesChartSeries] = useState<seriesType[]>([]);
  const [compaiesChartCategories, setCompaiesChartCategories] = useState<string[]>([]);
  const [showCompaniesChart,setShowCompaniesChart] = useState<boolean>(false);

  //        workcenter and or subsystem statistics
   // =>workcenter/subsystem column chart
   const [subsWorkCChartSeries, setSubsWorkCChartSeries] = useState<seriesType[]>([]);
   const [subsWorkCChartCategories, setSubsWorkCChartCategories] = useState<string[]>([]);
   const [showSubWorkGraph,setShowSubWorkGraph] = useState<boolean>(false);

  //      workcenter statistics for SS
  // => Workcenter column chart
  const [showWorkGraph,setShowWorkGraph] = useState<boolean>(false);
  const [workCChartSeries, setWorkCChartSeries] = useState<seriesType[]>([]);
  const [workCChartCategories, setWorkCChartCategories] = useState<string[]>([]);

  // tour
  const { setOpenTour, openTour, isFirstTime } = useTourCheck('dashboard');

  const steps: TourProps["steps"] = [
    {
      title: "Gráficas",
      description:
        "En las gráficas que aparecen a continuación podrá ver un resumen del estado en el que se encuentran los expedientes que han sido registrados por su plantel o subsistema. ",
    },
    {
      title: "Aviso",
      description: "La Subsecretaria de Educación Media Superior y Superior tiene la encomienda de revisar los documentos adjuntos a cada uno de los expedientes y enviar comentarios a los planteles que así corresponda para lograr la correcta integración de los expedientes. Le agradecemos estar al pendiente de las notificaciones que reciba en su correo electrónico y revisar de manera periódica este reporte",
      placement:"center"
    },
  ];

  const GroupAgreementsByStatus = () => {
    setActiveAgreements(
      agreements.filter((agreement) => agreement.status === "ACTIVO")
    );
    setRejectedAgreements(
      agreements.filter((agreement) => agreement.status === "RECHAZADO")
    );
    setPendingdAgreements(
      agreements.filter(
        (agreement) => agreement.status === "PENDIENTE DE REVISION"
      )
    );
    setDraftAgreements(
      agreements.filter(
        (agreement) => agreement.status === "BORRADOR"
      )
    );
  };

  const GetAgreementsLimitedList = () => {
    if (user?.role === 1) {
      setAgreementsPendingLimited(pendingAgreements?.slice(0, 10));
      setAgreementsRejectedLimited(rejectedAgreements?.slice(-10));
    } else {
      setAgreementsPendingLimited(pendingAgreements?.slice(-10));
      setAgreementsRejectedLimited(rejectedAgreements?.slice(0,10));
    }
  };

  const GetCompaniesLimitedList = () => {
    if(user?.role === 1) {
      setCompaniesLimitedList(companies.filter((comp) => comp.status === "Sin Verificar").slice(0,5))
    } else {
      setCompaniesLimitedList(companies.filter((comp) => comp.status === "Rechazada").slice(0,5))
    }
  }

  const setCompanyChartData = () => {
    if(companiesStat  && companiesStat.length>0){
      setShowCompaniesChart(true)
      const compNames = companiesStat.map((c)=>c.company.name)
      setCompaiesChartCategories(compNames)
      const compSeries: seriesType[] = [
        {
          name: "BORRADOR",
          data: companiesStat.map((c)=>c.draft as number)
        },
        {
          name: "ACTIVO",
          data: companiesStat.map((c)=>c.active as number)
        },
        {
          name: "PENDIENTE DE REVISIÓN",
          data: companiesStat.map((c)=>c.pending as number)
        },
        {
          name: "RECHAZADO",
          data: companiesStat.map((c)=>c.rejected as number)
        },
      ]
      setCompaniesChartSeries(compSeries)
    }
  }

  const setSubWorkCChartData = () => {
    if (!subsWorkCStats || subsWorkCStats.length <= 0 || !subsWorkCStats[0]._id) {
      return false;
    }
    setShowSubWorkGraph(true);

    const names = subsWorkCStats.map((c) => c._id);
    setSubsWorkCChartCategories(names);
    const SubWorkSeries: seriesType[] = [
      {
        name: "BORRADOR",
        data: subsWorkCStats.map((c) => c.draft as number),
      },
      {
        name: "ACTIVO",
        data: subsWorkCStats.map((c) => c.active as number),
      },
      {
        name: "PENDIENTE DE REVISIÓN",
        data: subsWorkCStats.map((c) => c.pending as number),
      },
      {
        name: "RECHAZADO",
        data: subsWorkCStats.map((c) => c.rejected as number),
      },
    ];
    setSubsWorkCChartSeries(SubWorkSeries);
  };

  const setWorkCChartData = () => {
    if (!workCStats || workCStats.length <= 0 || !workCStats[0]._id || user?.role !== 1) {
      return false;
    }
    setShowWorkGraph(true);

    const names = workCStats.map((c) => c._id);
    setWorkCChartCategories(names);
    const workSeries: seriesType[] = [
      {
        name: "BORRADOR",
        data: workCStats.map((c) => c.draft as number),
      },
      {
        name: "ACTIVO",
        data: workCStats.map((c) => c.active as number),
      },
      {
        name: "PENDIENTE DE REVISIÓN",
        data: workCStats.map((c) => c.pending as number),
      },
      {
        name: "RECHAZADO",
        data: workCStats.map((c) => c.rejected as number),
      },
    ];
    setWorkCChartSeries(workSeries);
  };

  useEffect(() => {
    if (agreementStateStatus !== "fulfilled") {
      dispatch(agreementsDoList());
    } else {
      GroupAgreementsByStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, agreementStateStatus, agreements]);

  useEffect(() => {
    if (companyStateStatus !== "fulfilled") {
      dispatch(companiesDoList());
    } else {
      GetCompaniesLimitedList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, companyStateStatus, companies]);

  useEffect(() => {
    if (companiesStatStatus !== "fulfilled") {
      dispatch(doListCompStatistics());
    } else {
      setCompanyChartData()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, companiesStatStatus, companiesStat]);

  useEffect(() => {
    if (subsWorkCStatsStatus !== "fulfilled") {
      dispatch(doListSubWorkCStatistics());
    } else {
      setSubWorkCChartData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, subsWorkCStatsStatus, subsWorkCStats]);

  useEffect(() => {
    if (workCStatsStatus !== "fulfilled") {
      dispatch(doListWorkCStatistics());
    } else {
      setWorkCChartData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, workCStatsStatus, workCStats]);


  useEffect(() => {
    if (activeAgreements && rejectedAgreements && pendingAgreements && draftAgreements) {
      setSeriesDonutChart([
        draftAgreements?.length,
        activeAgreements?.length,
        pendingAgreements?.length,
        rejectedAgreements?.length,
      ]);
      GetAgreementsLimitedList();
      if(activeAgreements?.length +  rejectedAgreements?.length + pendingAgreements?.length + draftAgreements?.length > 0) {
        setShowshowDonutChart(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeAgreements, pendingAgreements, rejectedAgreements]);

  return (
    <S.Wrapper>
      <S.Card
        id="agreements-list"
        title={
          <>
            <span>
              Inicio - estadísticas{" "}
              <InfoButton onClick={() => setOpenTour(true)} />
            </span>
          </>
        }
        padding="1.25rem"
      >
        <Content>
          <br />
          <S.Card bordered={false} size="small">
            <Row gutter={16}>
              <Col span={6}>
                <h3>Expedientes activos</h3>
              </Col>
              <Col span={6}>
                <h3>Expedientes pendientes de revisión</h3>
              </Col>
              <Col span={6}>
                <h3>Expedientes en estado borrador</h3>
              </Col>
              <Col span={6}>
                <h3>Expedientes rechazados</h3>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={6}>
                <Statistic
                  value={activeAgreements?.length}
                  valueStyle={{ color: "#3f8600", fontWeight: "bold", fontSize: 35 }}
                  prefix={<ArrowUpOutlined />}
                />
              </Col>
              <Col span={6}>
                <Statistic
                  value={pendingAgreements?.length}
                  valueStyle={{ color: "#faad14", fontWeight: "bold",  fontSize: 35  }}
                  prefix={<ClockCircleOutlined />}
                />
              </Col>
              <Col span={6}>
                <Statistic
                  value={draftAgreements?.length}
                  valueStyle={{ color: "#b36d19", fontWeight: "bold", fontSize: 35}}
                  prefix={<EditOutlined />}
                />
              </Col>
              <Col span={6}>
                <Statistic
                  value={rejectedAgreements?.length}
                  valueStyle={{ color: "#cf1322", fontWeight: "bold", fontSize: 35 }}
                  prefix={<ArrowDownOutlined />}
                />
              </Col>
            </Row>
          </S.Card>

          <br />
          <Row gutter={16}>
            <Col span={12}>
              <Space
                direction="vertical"
                size="middle"
                style={{ display: "flex" }}
              >
                <AntCard title={`Estado de Expedientes`}>
                  {seriesDonutChart && showDonutChart === true ? (
                    <DonutChart
                      series={seriesDonutChart}
                      labels={labelsDonutChart}
                    ></DonutChart>
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                  )}
                </AntCard>
              </Space>
            </Col>
            <Col span={12}>
              <AntCard
                title={
                  user?.role === 1
                    ? "Empresas sin verificar"
                    : "Empresas rechazadas"
                }
              >
                {companiesLimitedList && (
                  <CompaniesList
                    companies={companiesLimitedList}
                  ></CompaniesList>
                )}
              </AntCard>
            </Col>
          </Row>
          <br />
          <Row gutter={16}>
            <Col span={12}>
              <Tooltip
                title={
                  user?.role === 1
                    ? `Primeros 10 expedientes pendientes de revisión`
                    : `Ultimos 10 expedientes pendientes de revisión`
                }
              >
                <AntCard title={`expedientes pendientes de revisión`}>
                  {agreementsPendingLimited && (
                    <AgreementsList
                      agreements={agreementsPendingLimited}
                    ></AgreementsList>
                  )}
                </AntCard>
              </Tooltip>
            </Col>
            <Col span={12}>
              <Tooltip
                title={
                  user?.role === 1
                    ? `Ultimos 10 expedientes rechazados`
                    : `Primeros 10 expedientes Rechazados`
                }
              >
                <AntCard title={`expedientes rechazados`}>
                  {agreementsRejectedLimited && (
                    <AgreementsList
                      agreements={agreementsRejectedLimited}
                    ></AgreementsList>
                  )}
                </AntCard>
              </Tooltip>
            </Col>
          </Row>
          <br />
          {showSubWorkGraph === true && (
            <Row gutter={16}>
              <Col span={24}>
                <AntCard
                  title={
                    user?.role === 1
                      ? "Estatus de expedientes por subsistema"
                      : "Estatus de expedientes por plantel"
                  }
                >
                  {subsWorkCChartSeries && subsWorkCChartCategories && (
                    <ColumnChart
                      series={subsWorkCChartSeries}
                      categories={subsWorkCChartCategories}
                    ></ColumnChart>
                  )}
                </AntCard>
              </Col>
            </Row>
          )}
          <br />
          {showWorkGraph === true && (
            <Row gutter={16}>
              <Col span={24}>
                <AntCard title={"Estatus de expedientes por plantel"}>
                  {workCChartSeries && workCChartCategories && (
                    <ColumnChart
                      series={workCChartSeries}
                      categories={workCChartCategories}
                    ></ColumnChart>
                  )}
                </AntCard>
              </Col>
            </Row>
          )}
          <br />
          <Row gutter={16}>
            <Col span={24}>
              <AntCard
                title={`Empresas con mayor número de expedientes rechazados o pendientes de revisión`}
              >
                {compaiesChartCategories &&
                companiesChartSeries &&
                showCompaniesChart === true ? (
                  <ColumnChart
                    series={companiesChartSeries}
                    categories={compaiesChartCategories}
                  ></ColumnChart>
                ) : (
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
                )}
              </AntCard>
            </Col>
          </Row>
        </Content>
      </S.Card>
      <InfoTour
        onClose={() => setOpenTour(false)}
        open={openTour}
        steps={steps}
        block="start"
        module="dashboard"
        isFirstTime={isFirstTime}
      />
    </S.Wrapper>
  );
};
