import { Col, DatePicker, FormInstance, Input, Row } from "antd";
import { ShowDocumentBtn } from "components/common/Document/ShowDocumentBtn";
import { AWSFileUploader } from "components/common/FileManager/AWSFileUploader";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";

interface laptopFieldsProps {
  onShowDocument: (id: string) => any;
  onUpload: (field: string, id: string) => any;
  form: FormInstance;
}

export const LaptopFields = ({
  form,
  onShowDocument,
  onUpload,
}: laptopFieldsProps) => (
  <Row>
    <Col flex={1}>
      <BaseForm.Item
        name="laptopAssignmentFileId"
        label="Responsiva de entrega de laptop"
      >
        {form.getFieldValue("laptopAssignmentFileId") ? (
          <>
            <ShowDocumentBtn
              onClick={() => {
                onShowDocument(form.getFieldValue("laptopAssignmentFileId"));
              }}
            />
            <br />
          </>
        ) : (
          "No hay Responsiva de entrega de laptop "
        )}
        <AWSFileUploader
          title="Responsiva de entrega de laptop"
          bucket="laptop-Assignment"
          onUploadCompleted={(id: string[]) => {
            onUpload("laptopAssignmentFileId", id[0])
          }}
          once
        />
      </BaseForm.Item>
    </Col>
    <Col flex={1} style={{ marginRight: 50 }}>
      <BaseForm.Item
        name="laptopSN"
        label={"Numero de serie de laptop"}
        rules={[{ required: false, message: "Este campo es obligatorio" }]}
      >
        <Input placeholder="Número de serie" />
      </BaseForm.Item>
    </Col>
    <Col flex={1}>
      <BaseForm.Item
        name="laptopDeliveryDate"
        label={"Fecha de entrega de laptop"}
      >
        <DatePicker />
      </BaseForm.Item>
    </Col>
  </Row>
);
