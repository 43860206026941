import { Button, Form, Modal, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { notificationController } from "controllers/notificationController";
import { useAppDispatch } from "hooks/reduxHooks";
import { useState } from "react";
import { Company, Representative, UpdateStatusCompanyProps } from "./types";
import { doUpdateStatus } from "store/slices/companySlice";
import { doUpdateStatus as doUpdateStatusRep} from "store/slices/representativeSlice";

type CommentsModalProps = {
  active: boolean;
  toggle: any;
  close?: any;
  selectedRow: Company;
  rep?: Representative;
  statusCompany?: string;
};

export const CommentsModal = ({
  active,
  toggle,
  close,
  selectedRow,
  rep,
  statusCompany,
}: CommentsModalProps) => {
  const dispatch = useAppDispatch();
  // const [modalVisible, setModalVisible] = useState(active);
  const [valuesTextArea, setValuesTextArea] = useState<string>("");
  const [loading, setLoading] = useState(false);

const handleOk = () => {
  setLoading(true);
  const new_status: UpdateStatusCompanyProps = {
    id: selectedRow._id ?? "",
    status: statusCompany,
    comment: rep
      ? "Representante " + rep?.name + " rechazado: " + valuesTextArea
      : valuesTextArea,
  };
  if(rep) {
    dispatch(
        doUpdateStatusRep({
          id: rep._id?.toString() ?? "",
          status:"Rechazada",
        })
      )
  }
  dispatch(doUpdateStatus(new_status))
    .unwrap()
    .then(() => {
      notificationController.success({
        message: "Empresa Actualizada",
      });
      toggle();
      if(close){
        close(false);
        }
    })
    .catch((error) => {
      notificationController.error({
        message: "Error ",
      });
      toggle();
      if(close){
        close(false);
        }
    });
  setLoading(false);
};
  const handleCancel = () => {
    toggle();
  };
  const handleChange = (e: any) => {
    setValuesTextArea(e.target.value);
  };

  return (
    <>
      <Space>
        <Modal
          title='Ingresa tus comentarios'
          open={active}
          destroyOnClose
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button type='primary' onClick={handleOk} loading={loading}>
              Enviar
            </Button>,
            <Button type='primary' danger onClick={handleCancel}>
              Cancelar
            </Button>,
          ]}
        >
          <Form layout='vertical' style={{ marginTop: 25 }} onFinish={handleOk}>
            <Form.Item>
              <TextArea
                size='small'
                allowClear
                required
                onChange={handleChange}
              />
            </Form.Item>
          </Form>
        </Modal>
      </Space>
    </>
  );
};
