import { Button, Descriptions, Drawer, Segmented, Select, Space, Table, Tooltip, TourProps } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { doList } from "store/slices/agreementSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Agreement } from "components/Agreements/types";
import { Student } from "components/students/types";
import { WorkCenter } from "components/workcenters/types";
import { Company } from "components/companies/types";
import { AgreementDetail } from "../Agreements/AgreementDetail";
import { initialValues } from "../Agreements/types";
import { filterProps } from "components/common/FilterProps";
import { ColumnsType } from "antd/es/table";
import * as S from "../Agreements/Agreements.styles";
import { colorStatus } from "../Agreements/Agreements.styles";
import { OptionsDrawerBar } from "../Agreements/details/OptionsDrawerBar";
import { ComentsAgreement } from "../Agreements/details/ComentsAgreement";
import { Modal } from "../common/Modal/Modal.styles";
import { CommentsOptions } from "../Agreements/CommentsOptions";
import { valueDate } from "hooks/dateTreatment";
import { InfoTour } from "components/common/InfoTour/InfoTour";
import { InfoButton } from "components/common/buttons/Button/InfoButton";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions"
import { doGetApprovalProcess } from "store/slices/configSlice";
import { useTourCheck } from "hooks/useTourCheck";

const {writeAgreements} = PermissionsToAccess
export const getFullDate = (date: string): string => {
  const dateAndTime = date.split("T");
  return dateAndTime[0].split("-").reverse().join("-");
};

export const FollowList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { agreements, status: agreementStateStatus } = useAppSelector((state) => state.agreement);
  const { user } = useAppSelector((state) => state.user);
  const {validApprovalRoles, configStatus }  = useAppSelector((state) => state.config);
  const [agreementColumns, setAgreementColumns] = useState<Agreement[]>();
  const [selectedRowData, setSelectedRowData] = useState<Agreement>();
  const [agreementComents, setAgreementComents] = useState<Agreement>();
  // filters
  const segmentedOptions = ["Expedientes vencidos","Expedientes cerca de finalizar","Expedientes por aprobar"]
  const [filter, setFilter] = useState<string | number>(segmentedOptions[0]);
  const [daysFilter, setDaysFilter] = useState<number>(30);
  const [statusFilter, setStatusFilter] = useState<string>("PERSONAL");
  // tour
  const { setOpenTour, openTour, isFirstTime } = useTourCheck('followAgreement');
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const steps: TourProps["steps"] = [
    {
      title: "Sección de seguimiento de expedientes",
      description:
        "En esta seción podrá visualizar los expedientes establecidos entre su plantel, los estudiantes y las empresas participantes en Educación Dual.",
        target: () => ref1.current,
    },
    {
      title: "filtros de expedientes",
      description: "Puede filtrar los expedientes que se muestran utilizando la barra de opciones",
      target: () => ref2.current,
      placement:"left"
    },
    {
      title: "Tabla, filtros y búsqueda",
      description: "Para realizar la búsqueda de expedientes ya registrados podrá hacer uso de los filtros que aparecen en el encabezado de la tabla",
      placement: "bottom"
    },
  ];
  // Modal
  const [modalVisible, setModalVisible] = useState(false);
  // Drawer
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onCloseDrawer = () => {
    setSelectedRowData(initialValues);
    setOpen(false);
  };
  const checkPersonalPendings = (agreement: Agreement) => {
    if (
      user?.role === 1 &&
      JSON.stringify(agreement.approvalStep) === JSON.stringify(validApprovalRoles[0])
    ) {
      return true;
    }
    if (
      JSON.stringify(agreement.approvalStep) === JSON.stringify(validApprovalRoles[0]) &&
      JSON.stringify(agreement.approvalStep) === JSON.stringify(user?.approvementRole)
    ) {
      return true;
    }
    if (
      user?.role !== 1 &&
      JSON.stringify(agreement.approvalStep) === JSON.stringify(user?.approvementRole)
    ) {
      return true;
    }
    if (user?.role !== 1 && !user?.approvementRole && !agreement.approvalStep) {
      return true;
    }
    return false;
  };
  const handleFilterChange = () => {
    let filteredAgreements: Agreement[] = agreements;
    var today = new Date();
    if (filter === segmentedOptions[0]) {
      filteredAgreements = agreements.filter((agreement) => {
        const endDate = new Date(agreement.endDate);
        return agreement.endDate && endDate < today && agreement.status !== "INACTIVO";
      });
    }
    if (filter === segmentedOptions[1]) {
      filteredAgreements = agreements.filter((agreement) => {
        const endDate = new Date(agreement.endDate);
        const timeDifference = endDate.getTime() - today.getTime();
        const daysDifference = timeDifference / (1000 * 3600 * 24);
        return (
          agreement.endDate &&
          daysDifference <= daysFilter &&
          daysDifference >= 0
          && agreement.status !== "INACTIVO"
        );
      });
    }
    if (filter === segmentedOptions[2] && statusFilter !== "PERSONAL") {
      filteredAgreements = agreements.filter((agreement) => {
        return agreement.status && agreement.status === statusFilter ;
      });
    }
    if (filter === segmentedOptions[2] && statusFilter === "PERSONAL") {
      filteredAgreements = agreements.filter((agreement) => {
        return agreement.status !== "ACTIVO" && agreement.status !== "INACTIVO" && checkPersonalPendings(agreement);
      });
    }
    setAgreementColumns(filteredAgreements);
  };

  useEffect(() => {
    if(agreementStateStatus !== "fulfilled"){
      dispatch(doList());
    }
  }, [dispatch, agreementStateStatus, agreements]);
  useEffect(() => {
    if (configStatus !== "fulfilled") {
      dispatch(doGetApprovalProcess());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, validApprovalRoles, configStatus]);
  useEffect(() => {
    handleFilterChange()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreements, filter, daysFilter, statusFilter]);

  const columns: ColumnsType<Agreement> = [
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (value: any, record: Agreement, index: number) => (
        <span onMouseEnter={(e) => e.preventDefault()}>
          <Tooltip title= {record.approvalStep ? `${value} - ${record.approvalStep.name}` : `${value}`}>
            <FontAwesomeIcon
              icon={solid("circle")}
              style={colorStatus(value)}
            />
          </Tooltip>
        </span>
      ),
      ...filterProps,
      onFilter: (value, record) => {
        return record.status
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a, b) => a.status.localeCompare(b.status),
    },
    {
      title: "Folio",
      dataIndex: "sequence",
      key: "sequence",
      align: "center",
      render: (value: any, record: Agreement, index: number) =>
      {return record.sequence},
      sorter: (a, b) =>
      a.sequence && b.sequence
      ? a.sequence - b.sequence
      : -1
    },
    {
      title: "Fecha de Finalizacion",
      dataIndex: "endDate",
      key: "endDate",
      align: "center",
      render: (value: any, record: Agreement, index: number) =>
        valueDate(value),
      ...filterProps,
      onFilter: (value, record) => {
        let recordDateString = valueDate(record.endDate);
        recordDateString = recordDateString !== false ? recordDateString : ""
        return recordDateString
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter:  (a, b) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime()
    },
    {
      title: "Alumno",
      dataIndex: "studentId",
      key: "student",
      render: (student: Student) =>
        student ? `${student.name} ${student.paternalLastname ?? ""} ${student.maternalLastname ?? ""}` : "",
      ...filterProps,
      onFilter: (value, record) => {
        const fullName = `${record.studentId?.name} ${record.studentId?.paternalLastname ?? ""} ${record.studentId?.maternalLastname ?? ""}`;
        return fullName.toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) => {
        const fullNameA = `${a.studentId.name} ${a.studentId.paternalLastname ?? ""} ${a.studentId.maternalLastname ?? ""}`;
        const fullNameB = `${b.studentId.name} ${b.studentId.paternalLastname ?? ""} ${b.studentId.maternalLastname ?? ""}`;
        return fullNameA.localeCompare(fullNameB);
      },
    },
    {
      title: "Plantel",
      dataIndex: "workCenterId",
      key: "workCenter",
      ...filterProps,
      render: (workCenterId: WorkCenter) => workCenterId && workCenterId.nameShort && (`${workCenterId?.nameShort ?? ""}`),
      onFilter: (value, record) => {
        return record.workCenterId && record.workCenterId.nameShort
        ? record.workCenterId.nameShort.toLowerCase()
          .includes(value.toString().toLowerCase())
        : "".toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) => a.workCenterId?.nameShort.localeCompare(b.workCenterId?.nameShort),
    },
    {
      title: "Empresa",
      dataIndex: "companyId",
      key: "companies",
      ...filterProps,
      render: (companyId: Company) => {
        return (
          <>
            {companyId && <p>
              {companyId?.name ?? ""}{" "}
             {companyId.status === "Verificada" && <Tooltip title="Verificada">
                <FontAwesomeIcon icon={solid("circle-check")}className="verified-badge"/>
            </Tooltip>}
            </p>}
          </>
        );
    },
      onFilter: (value, record) => {
        return record.companyId && record.companyId.name 
          ? record.companyId?.name.toLowerCase()
            .includes(value.toString().toLowerCase()) || false
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) => a.companyId && b.companyId ? a.companyId.name.localeCompare(b.companyId.name) : -1,
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "x",
      align: "center",
      render: (data: Agreement) => {
        return (
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Button
            style={{marginRight: "10%"}}
              icon={
                <FontAwesomeIcon
                  icon={solid("comment-dots")}
                  style={{ color: "#001529" }}
                />
              }
              onClick={() => {
                showModal(data);
              }}
            />
          </span>
        );
      },
    },
  ];

  const showModal = (record: Agreement) => {
    setAgreementComents(record);
    setModalVisible(true);
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  return (
    <>
      <S.Wrapper id="agreements-list-container">
        <S.Card
          id="agreements-list"
          title={
            <>
              <span ref={ref1}>
                Seguimiento de expedientes{" "}
                <InfoButton onClick={() => setOpenTour(true)} />
              </span>
            </>
          }
          padding="1.25rem"
        >
          <Drawer
            title="Detalles del expediente Dual"
            placement="right"
            width="50%"
            onClose={onCloseDrawer}
            destroyOnClose
            open={open}
            extra={
              <OptionsDrawerBar
                rowSelected={selectedRowData ?? initialValues}
                closeDrawer={setOpen}
              />
            }
          >
            <AgreementDetail
              selectedRow={selectedRowData ?? initialValues}
              closeModalDetails={setModalVisible}
            />
          </Drawer>
          <Segmented block options={segmentedOptions} onChange={setFilter} ref={ref2} />
          <br />
          {filter === segmentedOptions[1] && (
            <Space>
              <p>Mostrar expedientes que finalizan en los próximos:</p>
              <Select
                defaultValue={30}
                value={daysFilter}
                onChange={setDaysFilter}
                style={{ width: 120 }}
                options={[
                  { value: 7, label: "7 días" },
                  { value: 15, label: "15 días" },
                  { value: 30, label: "30 días" },
                  { value: 60, label: "60 días" },
                ]}
              />
            </Space>
          )}
            {filter === segmentedOptions[2] && (
            <Space>
              <p>Mostrar expedientes con estado de:</p>
              <Select
                defaultValue={"PERSONAL"}
                value={statusFilter}
                onChange={setStatusFilter}
                style={{ width: 200 }}
                options={[
                  { value: "PENDIENTE DE REVISION", label: "Pendiente de revisión" },
                  { value: "BORRADOR", label: "Borrador" },
                  { value: "RECHAZADO", label: "Rechazado" },
                  { value: "PERSONAL", label: "Pendientes personales" },
                ]}
              />
            </Space>
          )}
          <Table
            dataSource={agreementColumns}
            columns={
              CheckAccess(writeAgreements)
                ? columns
                : columns.filter((col) => col.title !== "Action")
            }
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setSelectedRowData(record);
                  showDrawer();
                },
              };
            }}
            size="small"
            rowKey="_id"
          />
          <Descriptions title="Estados" layout="vertical">
            <Descriptions.Item label="Activo">
              <FontAwesomeIcon
                icon={solid("circle")}
                style={colorStatus("ACTIVO")}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Pendiente de revisión">
              <FontAwesomeIcon
                icon={solid("circle")}
                style={colorStatus("PENDIENTE DE REVISION")}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Borrador">
              <FontAwesomeIcon
                icon={solid("circle")}
                style={colorStatus("BORRADOR")}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Rechazado">
              <FontAwesomeIcon
                icon={solid("circle")}
                style={colorStatus("RECHAZADO")}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Inactivo">
              <FontAwesomeIcon
                icon={solid("circle")}
                style={colorStatus("INACTIVO")}
              />
            </Descriptions.Item>
          </Descriptions>
          <Modal
            open={modalVisible}
            title="Comentarios"
            destroyOnClose
            onCancel={handleCancel}
            footer={
              <CommentsOptions
                closeModal={setModalVisible}
                selectedRow={agreementComents ?? initialValues}
              />
            }
          >
            <ComentsAgreement selectedRow={agreementComents ?? initialValues} />
          </Modal>
        </S.Card>
        <InfoTour
          onClose={() => setOpenTour(false)}
          open={openTour}
          steps={steps}
          block={"start"}
          module="followAgreement"
          isFirstTime = {isFirstTime}
        />
      </S.Wrapper>
    </>
  );
};
