import { CommentsType } from "../Agreements/types"

export const statusCompanyRep = ["Sin Verificar", "Rechazada", "Verificada"];
export const typeRepresentative = ["Contact", "Representative"];
export type Representative = {
  _id?: string;
  name: string;
  phone?: number;
  email: string;
  position: string;
  idFileId?: string;
  powerFileId?: string;
  companyId: string;
  status: typeof statusCompanyRep[number];
  type: typeof typeRepresentative[number];
};
export type Company = {
  incFileId?: string;
  fiscalFileId?: string;
  _id?: string;
  name: string;
  commercialName?: string;
  taxId: string;
  industry?: string;
  status: typeof statusCompanyRep[number];
  comments?: CommentsType[];
};

export const initialValuesRep: Representative = {
  name: "",
  phone: 9999999999,
  position: "",
  email: "",
  companyId: "",
  idFileId: undefined,
  powerFileId: undefined,
  status: "Sin Verificar",
  type: "Contact"
};
export const initialValues: Company = {
  name: "",
  commercialName: "",
  taxId: "",
  status: "Sin Verificar",
  incFileId: undefined,
  fiscalFileId: undefined,
};

export type SearchParams = {
  searchParam: string;
  searchValue: string;
};

export type UpdateStatusCompanyProps = {
  id: string; 
  comment?: string;
  status?: typeof statusCompanyRep[number];
};

export const reptype = [
  "Representante",
  "Contacto",
];

export const typeRelation: any = {
  Representative: "representante",
  Contact: "contacto",
  Representante: "Representative",
  Contacto: "Contact",
};
