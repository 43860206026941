/*eslint valid-typeof: off*/
import React, { useState, useEffect, useRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import {
  Select,
  DatePicker,
  Row,
  Col,
  Button,
  Form,
  Input,
  Tooltip,
  Progress,
  Spin,
  Divider
} from "antd";
import { doRegister } from "store/slices/agreementSlice";
import { doList as doCompanyList } from "store/slices/companySlice";
import { doList as pricipalDoList } from "store/slices/employeeSlice";
import { doList as SubsystemList } from "store/slices/subsystemSlice";
import {search as searchStudents} from "api/student.api"
import { notificationController } from "controllers/notificationController";
import * as S from "./Agreements.styles";
import { AGREEMENTS_PATH } from "components/router/AppRouter";
import { Company, Representative } from "components/companies/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { CompanyNew } from "../companies/CompanyNew";
import { WorkCenter } from "../workcenters/types";
import { OptionsSelect } from "types/optionsSelect";
import { AWSFileUploader } from "components/common/FileManager/AWSFileUploader";
import { doList as WorkcenterSub} from 'store/slices/workCenterSlice';
import { doList as repsDoList } from "store/slices/representativeSlice";
import { CloseButton } from "components/common/buttons/Button/CloseButton";
import { IsAdultStudent, handleTimeChange } from "./utils/utils";
import { Document } from "components/common/Document/Document";
import { ShowDocumentBtn } from "components/common/Document/ShowDocumentBtn";
import { debounce, escapeRegExp } from "lodash";
import { Student } from "components/students/types";
import { fileList, getFilesNewLearningAgreement } from "./utils/fileList";
import { RotationFields } from "./utils/RotationFields";
import { TimeFields } from "./utils/timeFields";

export const AgreementNewForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ form ] = Form.useForm()
  const laptopAssignmentHook = Form.useWatch('laptopAssignmentFileId', form);

  const [isLoading, setLoading] = useState(false);
  // Inital Data
  const { employees, status } = useAppSelector((state) => state.employee);
  const { companies, status: companyStateStatus } = useAppSelector((state) => state.company);
  const { representatives, status: repsStateStatus } = useAppSelector((state) => state.representative);
  const { workcenters,  status: workcenterStateStatus } = useAppSelector((state) => state.workcenter);
  const { subsystems,  status: subsystemStateStatus } = useAppSelector((state) => state.susbsystem);
  const { user } = useAppSelector((state) => state.user)
  // WorkCenter
  const [valueWorkCenter, setValueWorkCenter] = useState<WorkCenter[]>([]);
  const [optionWorkCenter, setOptionWorkCenter] = useState<OptionsSelect[]>([]);
  const [optionsSubsystem, setOptionsSubsystem] = useState<OptionsSelect[]>([]);
  const [valueSubSystem, setValueSubSystem] = useState();
  const [ optionsEmployee, setOptionsEmployee ] = useState<OptionsSelect[]>([]);
  // WorkCenter
  // const [valueStudent, setValueStudent] = useState<Student[]>([]);
  const [optionStudents, setOptionStudents] = useState<OptionsSelect[]>([]);
  // Company
  // Select for Compnay
  const [ companyContacts, setCompanyContacts] = useState<Representative[]>([]);
  const [companyValue, setCompanyValue] = useState<Company>();
  const [optionsCompany, setOptionsCompany] = useState<OptionsSelect[]>([]);
  const [optionsCompanyContact, setOptionsrCompanyContact] = useState<
    OptionsSelect[]
  >([]);
  const [companyLegalReps, setCompanyLegalReps] = useState<Representative[]>([]);
  const [optionsCompanyLegalReps, setOptionsCompanyLegalReps] = useState<
    OptionsSelect[]
  >([]);
// label and display if adult or not
  const [INETutorEstudiante, setINELabel] = useState("");
  const [letterDisplay, setLetterDisplay]  = useState("");
  // Drawer Comapny
  const [openDrawer, setOpenDrawer] = useState(false);
  const [newCompany, setnewCompany] = useState<Company>();
  //Documents
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState("");
  // fetch students search
  const [filteredStudents, setFilteredStudents] = useState<Student[]>()
  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);
  // total hours
  const [totalHours, setTotalHours] = useState<string>("00:00");
  // show laptop fields
  const [visibleLaptopFields, setVisibleLaptopFields] = useState(false);
  //progress bar
 const [percent, setPercent] = useState<number>(0);
 const [isAdultStudent, setIsAdultStudent] = useState(false)
 const [fieldsForProgress, setFieldsForProgress] = useState({
  sequence: "",
  studentId: "",
  workCenterId: "",
  companyId: "",
  representativeId: "",
  legalRepresentativeId: "",
  startDate: "",
  endDate: "",
  learningAgreementFileId: "",
  colaborationAgreementFileId: "",
  tutorIdFileId: "",
  tutorAuthLetterFileId: "",
  insuranceFileId: "",
  rotationPlanFileId: "",
  learningPositionsFieldId: "",
  matrixFieldId: "",
  principalFileId: "",
  principal: "",
  principalPosition: "",
  rotationTimes: "",
  rotationPositions: "",
  hoursPerWeek: "",
});

  useEffect(() => {
    setINELabel("INE del padre o tutor vigente");
    setLetterDisplay("");
  }, [])
  useEffect(() => {
    showLaptopFields()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    if (companyStateStatus !== 'fulfilled'){
      dispatch(doCompanyList());
    }
    if ( repsStateStatus !== `fulfilled` ){
      dispatch(repsDoList())
    }
  },[companyStateStatus, dispatch, repsStateStatus]);
  // Select Functions
  useEffect(() => {
    if(workcenterStateStatus !== 'fulfilled'){
      dispatch(WorkcenterSub())
    }
  }, [dispatch, workcenterStateStatus]);

  useEffect(() => {
    if (subsystemStateStatus !== "fulfilled") {
      dispatch(SubsystemList());
    }
    const optionsRaw: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.nameShort ?? " - "} `,
      value: subs.nameShort!,
      key: subs._id!,
    }));
    setOptionsSubsystem(optionsRaw);
  }, [dispatch, subsystemStateStatus, subsystems]);

  useEffect(() => {
    if (status === 'empty'){
      dispatch(pricipalDoList())
    }
  }, [status, dispatch]);

  useEffect(() => {
    const principals = employees.filter((e) => typeof e.workcenter !== undefined).map((e) => ({
      label: e.name,
      value: e._id!,
      key: e._id!,
    }));
    setOptionsEmployee(principals)
  }, [employees]);

  useEffect(() => {
    if (newCompany) {
      handleCompanyChange(newCompany._id!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCompany]);

  useEffect(() => {
    CheckProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsForProgress, isAdultStudent]);

  //progress bar
  const CheckProgress = ()=>{
    let authLetterVal = form.getFieldValue("tutorAuthLetterFileId")
    let newProgress = percent;
    let numerator = Object.values(fieldsForProgress).filter(Boolean).length
    if (isAdultStudent && !authLetterVal) {
      numerator++;
    }
    newProgress = Math.round(
      (numerator / Object.keys(fieldsForProgress).length) * 100
    );
    setPercent(newProgress);
   }

  const handleTimeFieldsChange = (field: string, value: any) => {
    handleTimeChange(form,setTotalHours);
    handleFormChange(field, value)
  }

  const showLaptopFields = () => {
    let show = false
    if(!user){
      setVisibleLaptopFields(show);
      return false;
    }
    if (user.role && user.role === 1) {
      show= true
    }
    if(user.approvementRole && user.approvementRole?.sequence > 1) {
      show= true
    }
    setVisibleLaptopFields(show);
  }

  // documents
  const onShowDocument = (id: string) => {
    setPdfViewerId(id);
    setShowDocument(true);
  };

  const onUpload = (field: string, id:string) => {
    form.setFieldValue(`${field}`, id);
    handleFormChange(field ?? "", id);
  };

  const handleFormChange = (field: string, value: any) => {
    setFieldsForProgress({ ...fieldsForProgress, [field]: value });
  };
  // Select Functions

  const handleSubmit = (values: any) => {
    dispatch(doRegister(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: "Expediente registrado correctamente",
        });
        setLoading(false);
        navigate(AGREEMENTS_PATH);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({
          message: "Error al registrar, revisa los campos y las fechas. Evita folios duplicados. Revisa que el alumno y el plantel seleccionados coincidan",
        });
      });
  };

  // Students
  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value: string) => {
      if(value === "") {
        return;
      }
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setFetching(true);
      const workc = form.getFieldValue("workCenterId");
      const foundStudents = await searchStudents({ searchParam: "fullName", searchValue: value });
      let resultStuents = foundStudents;
      if(workc && workc!== ""){
        resultStuents = foundStudents.filter((student) => student.workcenter._id === workc)
      }
      const newOptions = resultStuents.map((student) => ({
        label: `${student.name} ${student.paternalLastname ?? ""} ${student.maternalLastname ?? ""}`,
        value: student._id!,
        key: student._id!,
      }));
      if (fetchId !== fetchRef.current) {
        return;
      }
      if(foundStudents.length >0){
        setFilteredStudents(foundStudents);
      }
      setOptionStudents(newOptions);
      setFetching(false);
    };

    return debounce(loadOptions, 400);
  }, [form]);

  const ifAdultChangeFiles = (newValue: any) => {
    handleFormChange("studentId", newValue);
    const studentSelected = filteredStudents!.find(
      (student) => student._id === newValue
    );
    if (!studentSelected) {
      return false;
    }
    let ineLabel = "INE del padre o tutor vigente";
    let display = "";
    let isAdultStudent = false
    let authLetterVal = form.getFieldValue("tutorAuthLetterFileId")

    if (IsAdultStudent(studentSelected)) {
      ineLabel = "INE del estudiante mayor de edad";
      isAdultStudent = true;
    }
    if (isAdultStudent && !authLetterVal) {
      display = "none";
    }
    setINELabel(ineLabel);
    setLetterDisplay(display);
    setIsAdultStudent(isAdultStudent);
  };
  const handleWorkCenterChange = async (newValue: any) => {
    setOptionStudents([]);
    setFetching(true);
    const foundStudents = await searchStudents({ searchParam: "workCenterId", searchValue: newValue });
    setFilteredStudents(foundStudents);
    const newOptions = foundStudents.map((student) => ({
      label: `${student.name} ${student.paternalLastname ?? ""} ${student.maternalLastname ?? ""}`,
      value: student._id!,
      key: student._id!,
    }));
    setOptionStudents(newOptions);
    setFetching(false);
  };

  //Work Center
  useEffect(() => {
    const optionsRaw: OptionsSelect[] = workcenters.map((workCenter) => ({
      label: workCenter.name!,
      value: workCenter._id!,
      key: workCenter._id!,
    }));
    setOptionWorkCenter(optionsRaw);
  }, [workcenters]);

  const changeSubsystem = (subsystemSelected: any) => {
    const newOptions= workcenters.filter(value => value.subsystemShort === subsystemSelected ).map((workCenter) => ({
      label: workCenter.name!,
      value: workCenter._id!,
      key: workCenter._id!,
    }));
    setOptionWorkCenter(newOptions);
    setValueSubSystem(subsystemSelected);
  };
  const filterWorkcenter = (workCenterName: string) => {
    let escapedWorkcName = escapeRegExp(workCenterName);
    let inputString= new RegExp(`${escapedWorkcName}`, 'gi');
    const newOptions = workcenters.filter(value => inputString.test(value.name)).map((workCenter) => ({
      label: workCenter.name!,
      value: workCenter._id!,
      key: workCenter._id!,
    }));;
    setOptionWorkCenter(newOptions);
  }
  // Company

  useEffect(() => {
    
    const optionsRaw: OptionsSelect[] = companies.map((company) => ({
      label: company.name!,
      value: company._id!,
      key: company._id!,
    }));
    setOptionsCompany(optionsRaw);
  }, [companies]);

  useEffect(() => {
    const optionsRaw: OptionsSelect[] = companyContacts.map((rep) => ({
      label: `${rep.name}`,
      value: rep._id!,
      key: rep._id!,
    }));
    const optionsRawReps: OptionsSelect[] = companyLegalReps.map((rep) => ({
      label: `${rep.name}`,
      value: rep._id!,
      key: rep._id!,
    }));
    setOptionsrCompanyContact(optionsRaw);
    setOptionsCompanyLegalReps(optionsRawReps);
    handleFormChange('representativeId', '')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyContacts, representatives, companyLegalReps]);

  const handleCompanyChange = (value: any) => {
    handleFormChange("companyId", value)
    if(newCompany) {
      form.setFieldValue("companyId",value)
    }
    form.setFieldValue('representativeId', null)
    form.setFieldValue('legalRepresentativeId', null)
    handleFormChange('legalRepresentativeId', '')
    setCompanyValue(value as Company);
    setCompanyContacts(representatives.filter((r) => (r.companyId === value) && r.type === "Contact"));
    setCompanyLegalReps(representatives.filter((r) => (r.companyId === value) && r.type === "Representative"))
  };

   const filterCompany = (companyName: string) => {
    let escapedCompanyName = escapeRegExp(companyName);
    let inputString= new RegExp(`${escapedCompanyName}`, 'gi');
    const newOptions = companies.filter(value => inputString.test(value.name)).map((company) => ({
      label: company.name!,
      value: company._id!,
      key: company._id!,
    }));;
    setOptionsCompany(newOptions);
  }

  const onClose = async () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <S.Wrapper>
        <S.SmallCard
        id='agreement-new'
        title={
          <>
            <CloseButton onClick={()=>{navigate(AGREEMENTS_PATH)}}></CloseButton>
            <span>&nbsp; {`Nuevo Expediente`}</span>
          </>
        }
        padding='3rem'>
           <Tooltip title={percent === 100 ? "Campos completos" : "Campos incompletos"}>
            <Progress percent={percent} />
          </Tooltip>
          <BaseForm
            layout='vertical'
            form={form}
            onFinish={handleSubmit}
            requiredMark='optional'
          >
            {/* Plantel */}
            <Row>
            </Row>
            <Row>
              <Col style={{ marginRight: 25 }}>
                <BaseForm.Item requiredMark={false} label={`Subsistema`}>
                  <Select
                    style={{ width: 200 }}
                    value={valueSubSystem}
                    onChange={changeSubsystem}
                    placeholder='Sub-Sistema'
                    options={optionsSubsystem}
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={1}>
                <BaseForm.Item
                  name='workCenterId'
                  label={`Plantel`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    showSearch
                    value={valueWorkCenter}
                    onSearch={filterWorkcenter}
                    onChange={(newValue) => {
                      setValueWorkCenter(newValue as WorkCenter[]);
                      handleWorkCenterChange(newValue);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ width: "100%" }}
                    options={optionWorkCenter}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
                <Col flex={1}>
                <BaseForm.Item
                  name="principal"
                  label="Director(a) del Plantel Firmante"
                  rules={[{ required: true, message: "Este campo es obligatorio" }]}
                >
                  <Select
                    showSearch
                    placeholder="Director(a) del plantel"
                    optionFilterProp="children"
                    onChange={(e) => handleFormChange('principal', e)}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={optionsEmployee}
                  />
                </BaseForm.Item>
                </Col>
                <Col flex={1} style={{ marginLeft: 20 }}>
                <BaseForm.Item
                  name="principalPosition"
                  label="Puesto del director(a) firmante"
                >
                  <Input
                    onChange={(e) => handleFormChange('principalPosition', e.target.value)}
                    placeholder="Encargado(a) de la Dirección del Plantel"
                  />
                </BaseForm.Item>
                </Col>
              </Row>
            {/* Student Data  */}
            <BaseForm.Item
              name='studentId'
              label={`Alumno`}
              rules={[{ required: true, message: "Este campo es obligatorio" }]}
            >
              <Select
                showSearch
                onSearch={debounceFetcher}
                placeholder='Busca al alumno por nombre'
                optionFilterProp='children'
                notFoundContent={fetching ? <Spin size='small' /> : null}
                onChange={(newValue) => {
                  ifAdultChangeFiles(newValue);
                }}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={optionStudents}
              />
            </BaseForm.Item>
            {/* Company Data  */}
            <Row>
              <Col flex={3} style={{ marginRight: 25 }}>
                <BaseForm.Item label={`Razón social`} required>
                  <Row>
                    <Col style={{ marginRight: 10 }} flex={1}>
                      {/* <Debounce /> */}
                      <BaseForm.Item
                        name='companyId'
                        rules={[
                          {
                            required: true,
                            message: "Este campo es obligatorio",
                          },
                        ]}
                      >
                        <Select
                          showSearch
                          value={companyValue}
                          onSearch={filterCompany}
                          onChange={handleCompanyChange}
                          placeholder='Selecciona un Empresa'
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                            (option?.label ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          style={{ minWidth: 350 }}
                          options={optionsCompany}
                        />
                      </BaseForm.Item>
                    </Col>
                    <Col>
                      <Button
                        type='primary'
                        onClick={() => {
                          setOpenDrawer(true);
                        }}
                        icon={
                          <FontAwesomeIcon
                            icon={solid("plus")}
                            style={{ marginRight: 5 }}
                          />
                        }
                      >
                        Nueva
                      </Button>
                    </Col>
                  </Row>
                </BaseForm.Item>
                <CompanyNew
                    open={openDrawer}
                    onClose={onClose}
                    onCompanySaved={setnewCompany}
                  />
              </Col>
              <Col flex={2} style={{marginRight: 10}}>
                <BaseForm.Item
                  name='representativeId'
                  label={`Contacto de la Empresa`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    key='CompanyContactSelect'
                    value={companyContacts}
                    options={optionsCompanyContact}
                    onChange={(e) => handleFormChange('representativeId', e)}
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={2}>
                <BaseForm.Item
                  name='legalRepresentativeId'
                  label={`Representante legal de la Empresa`}
                >
                  <Select
                    key='CompanyRepSelect'
                    value={companyLegalReps}
                    options={optionsCompanyLegalReps}
                    onChange={(e) => handleFormChange('legalRepresentativeId', e)}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <TimeFields handleTimeFieldsChange={handleTimeFieldsChange} totalHours={totalHours}/>
            <BaseForm.Item
              name="colaborationAgreementFileId"
              label="Convenio de Colaboración"
            >
              {form.getFieldValue("colaborationAgreementFileId") && (
                <>
                  <ShowDocumentBtn onClick={() => {
                    setPdfViewerId(form.getFieldValue("colaborationAgreementFileId") as string);
                    setShowDocument(true);
                  }}/>
                  <br />
                </>
              )}
              <AWSFileUploader
                title="Convenio de Colaboración"
                bucket="colaboration-agreement"
                onUploadCompleted={(id: string[]) => {
                  form.setFieldValue("colaborationAgreementFileId", id[0]);
                  handleFormChange("colaborationAgreementFileId", id[0]);
                }}
                once
              />
            </BaseForm.Item>
            {visibleLaptopFields && (
              <Row>
                <Col flex={1} >
                <BaseForm.Item
                    name="laptopAssignmentFileId"
                    label="Responsiva de entrega de laptop"
                  >
                    {laptopAssignmentHook&& (
                      <>
                        <ShowDocumentBtn
                          onClick={() => {
                            setPdfViewerId(
                              laptopAssignmentHook
                            );
                            setShowDocument(true);
                          }}
                        />
                        <br />
                      </>
                    )}
                    <AWSFileUploader
                      title="Responsiva de entrega de laptop"
                      bucket="laptop-Assignment"
                      onUploadCompleted={(id: string[]) => {
                        form.setFieldValue("laptopAssignmentFileId", id[0]);
                      }}
                      once
                    />
                  </BaseForm.Item>
                </Col>
                <Col flex={1} style={{ marginRight: 50 }}>
                  <BaseForm.Item
                    name="laptopSN"
                    label={"Numero de serie de laptop"}
                    rules={[
                      { required: false, message: "Este campo es obligatorio" },
                    ]}
                  >
                    <Input
                    placeholder='Número de serie'
                  />
                  </BaseForm.Item>
                </Col>
                <Col flex={1}>
                <BaseForm.Item
                  name='laptopDeliveryDate'
                  label={"Fecha de entrega de laptop"}
                >
                  <DatePicker />
                </BaseForm.Item>
              </Col>
              </Row>
            )}
            <Divider orientation="left">Convenio de Aprendizaje</Divider>
            {fileList(
              getFilesNewLearningAgreement(letterDisplay, INETutorEstudiante),
              onShowDocument,
              onUpload,
              form
            )}
            <br />
            <RotationFields onChange={handleFormChange}/>
            <BaseForm.Item noStyle>
              <S.SubmitButton
                type='primary'
                htmlType='submit'
                size='large'
                loading={isLoading}
              >
                {`Guardar borrador`}
              </S.SubmitButton>
            </BaseForm.Item>
            <Document
              fileId={pdfViewerId}
              open={showDocument}
              onCancel={() => {
                setShowDocument(false)
                setPdfViewerId("");
              }}
              onOk={() => setShowDocument(false)}
            />
          </BaseForm>
        </S.SmallCard>
      </S.Wrapper>
    </>
  );
};
