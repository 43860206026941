import { Table, Drawer, Spin, Button} from "antd";
import type { TourProps } from 'antd';
import React, { useEffect, useRef, useState } from "react";
import * as S from "../Student.styles";
import { doList } from "store/slices/studentSlice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { hoursMinutes, initialValues, Student } from "../types";
import { WorkCenter } from "../../workcenters/types";
import { ColumnsType } from "antd/es/table";
import { filterProps } from "components/common/FilterProps";
import { OptionsDrawerBar } from "../StudentDetail/OptionsDrawerBar";
import { StudentDetail } from "../StudentDetail/StudentDetail";
import { InfoTour } from "components/common/InfoTour/InfoTour";
import { InfoButton } from "components/common/buttons/Button/InfoButton";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions"
import { useTourCheck } from "hooks/useTourCheck";
import { colorCompletedHours } from '../../Agreements/Agreements.styles';
import { getHoursMinutesString } from "hooks/dateTreatment";
import { STUDENTS_PATH } from "components/router/AppRouter";
import { useNavigate } from "react-router-dom";

const {writeStudents} = PermissionsToAccess

export const StudentList: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState<Student[]>();
  const [selectedRowData, setSelectedRowData] = useState<Student>();
  const [spin, setSpin] = useState<boolean>(true)
  const { students, status: studentStateStatus } = useAppSelector((state) => state.student);

  // tour
  const { setOpenTour, openTour, isFirstTime } = useTourCheck('student');
  const ref1 = useRef(null);
  const ref2 = useRef(null);

  const steps: TourProps["steps"] = [
    {
      title: "Sección de alumnos",
      description:
        "En esta sección podrá consultar la información de las alumnas y alumnos que participan en Educación Dual.",
      target: () => ref1.current,
    },
    {
      title: "Nuevo alumno",
      description: "Para agregar un nuevo estudiante deberá presionar el botón “Nuevo alumno“ y agregar los datos correspondientes.",
      placement:"left",
      target: () => ref2.current
    },
    {
      title: "Tabla, filtros y búsqueda",
      description: "Para realizar la búsqueda de estudiantes ya registrados podrá hacer uso de los filtros que aparecen en el encabezado de la tabla",
      placement: "bottom"
    },
  ];

  useEffect(() => {
    if(studentStateStatus !== "fulfilled"){
      setSpin(true);
      dispatch(doList());
    } else{
      setSpin(false);
    }
  }, [dispatch, studentStateStatus]);
  useEffect(() => {
    setStudentData(students);
  }, [students]);

   // Drawer
   const [open, setOpen] = useState(false);
   const showDrawer = () => {
     setOpen(true);
   };
   const onCloseDrawer = () => {
     setSelectedRowData(initialValues);
     setOpen(false);
   };

  const columns: ColumnsType<Student> = [
    {
      title: "Nombre(s)",
      dataIndex: "name",
      key: "index",
      sorter: (a: Student, b: Student) => a.name.localeCompare(b.name),
      ...filterProps,
      onFilter: (value, record) => {
        return record.name
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Apellido paterno",
      dataIndex: "paternalLastname",
      key: "paternalLastname",
      sorter: (a: Student, b: Student) =>
        (a.paternalLastname ?? "").localeCompare(b.paternalLastname ?? ""),
      ...filterProps,
      onFilter: (value, record) =>
        (record.paternalLastname ?? "")
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "Apellido materno",
      dataIndex: "maternalLastname",
      key: "maternalLastname",
      sorter: (a: Student, b: Student) =>
        (a.maternalLastname ?? "").localeCompare(b.maternalLastname ?? ""),
      ...filterProps,
      onFilter: (value, record) =>
        (record.maternalLastname ?? "")
          .toLowerCase()
          .includes(value.toString().toLowerCase()),
    },
    {
      title: "CURP",
      dataIndex: "curp",
      key: "curp",
      ...filterProps,
      onFilter: (value, record) => {
        return record.curp
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: Student, b:Student) =>
      a.curp && b.curp
      ? a.curp.localeCompare(b.curp)
      : a.curp
      ? a.curp.localeCompare("")
      : b.curp
      ? b.curp.localeCompare("")
      : "".localeCompare(""),
    },
    {
      title: "Subsistema",
      dataIndex: "workcenter",
      key: "subsystem",
      render: (data: WorkCenter) => {
        return data ? data.subsystemShort : "";
      },
      ...filterProps,
      onFilter: (value, record) => {
        return record.workcenter?.subsystemShort
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: Student, b: Student) =>
        a.workcenter?.subsystemShort.localeCompare(b.workcenter?.subsystemShort),
    },
    {
      title: "Plantel",
      dataIndex: "workcenter",
      key: "workcenter",
      render: (data: WorkCenter) => {
        return data ? data.nameShort : "";
      },
      ...filterProps,
      onFilter: (value, record) => {
        return record.workcenter?.nameShort
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: Student, b: Student) =>
        a.workcenter?.nameShort.localeCompare(b.workcenter?.nameShort),
    },
    {
      title: "Estado académico",
      dataIndex: "academicStatus",
      key: "academicStatus",
      align: "center",
      render: (status: string) => {
        const textColor = S.colorStatus(status);
        let showStatus = status
        if (status.toLowerCase() === 'baja') {
          showStatus = 'Baja académica'
        }
        return (
          <p style={{ color: textColor }}>
          {showStatus ?? "Sin registro"}
        </p>
        )
      },
      ...filterProps,
      onFilter: (value, record) => {
        return record.academicStatus
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
      sorter: (a: Student, b: Student) =>
        a.academicStatus.localeCompare(b.academicStatus),
    },
    {
      title: "Horas completadas",
      dataIndex: "completedHours",
      key: "completedHours",
      align: "center",
      render: (completedHours: hoursMinutes) => {
        return (
          <p style={colorCompletedHours(completedHours ? completedHours.hours : 0)}>
          {completedHours ? getHoursMinutesString(completedHours) : "--"}
        </p>
        )
      },
      sorter: (a, b) => {
        if (a.completedHours && b.completedHours) {
          return a.completedHours.hours - b.completedHours.hours;
        } else if (a.completedHours) {
          return a.completedHours.hours - 0;
        } else if (b.completedHours) {
          return b.completedHours.hours - 0;
        } else {
          return -1;
        }
      }
    },
  ];

  return (
    <>
      <S.Wrapper>
        <S.Card
          id="students-list"
          title={
            <>
              <span ref={ref1}>
                Alumnos de educación dual <InfoButton onClick={() => setOpenTour(true)} />
              </span>
            </>
          }
          extra={
            CheckAccess(writeStudents) && (
              <Button
                type="primary"
                ref={ref2}
                onClick={() => {
                  navigate(`${STUDENTS_PATH}/nuevo`);
                }}
              >
                Nuevo alumno
              </Button>
            )
          }
          padding="1.25rem"
        >
          <Drawer
            title="Detalle del Alumno Dual"
            placement="right"
            width="50%"
            onClose={onCloseDrawer}
            destroyOnClose
            open={open}
            extra={
              CheckAccess(writeStudents) && (
                <OptionsDrawerBar
                  rowSelected={selectedRowData ?? initialValues}
                />
              )
            }
          >
            <StudentDetail selectedRow={selectedRowData ?? initialValues} />
          </Drawer>
          <Spin spinning={spin} delay={400} tip="Cargando..." size="large">
            <Table
              dataSource={studentData}
              columns={
                CheckAccess(writeStudents)
                  ? columns
                  : columns.filter((col) => col.title !== "Acción")
              }
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setSelectedRowData(record);
                    showDrawer();
                  },
                };
              }}
              tableLayout="fixed"
              size="middle"
              rowKey="_id"
            />
          </Spin>
        </S.Card>
        <InfoTour
          onClose={() => setOpenTour(false)}
          open={openTour}
          steps={steps}
          block="nearest"
          module="student"
          isFirstTime={isFirstTime}
        />
      </S.Wrapper>
    </>
  );
};
