import { Col, InputNumber, Row, Space, Statistic, Tour, TourProps } from "antd";
import { InfoButton } from "components/common/buttons/Button/InfoButton";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { DayjsDatePicker } from "components/common/pickers/DayjsDatePicker";
import { useRef, useState } from "react";

interface timeFieldsProps {
  handleTimeFieldsChange: (field: string, value: any) => any;
  totalHours: string;
}

export const TimeFields = ({
  handleTimeFieldsChange,
  totalHours,
}: timeFieldsProps) => {
  // tour
  const [openTour, setOpenTour] = useState(false);
  const ref1 = useRef(null);

  const steps: TourProps["steps"] = [
    {
      title: `Proyección de horas totales `,
      description: `Proyección aproximada de las horas de trabajo totales del expediente. Se calcula multiplicando el número de semanas COMPLETAS (Lunes a domingo) que hay entre las fechas de inicio y finalización, por el número de horas de trabajo a la semana`,
      target: () => ref1.current,
    },
  ];

  return (
    <>
      <Row>
        <Col flex={1}>
          <BaseForm.Item
            name="startDate"
            label={"Fecha de Inicio"}
            rules={[{ required: true, message: "Este campo es obligatorio" }]}
          >
            <DayjsDatePicker
              onChange={(e) => {
                handleTimeFieldsChange("startDate", e?.toDate());
              }}
            />
          </BaseForm.Item>
        </Col>
        <Col flex={1}>
          <BaseForm.Item
            name="endDate"
            label={"Fecha de Finalización"}
            rules={[{ required: true, message: "Este campo es obligatorio" }]}
          >
            <DayjsDatePicker
              onChange={(e) => {
                handleTimeFieldsChange("endDate", e?.toDate());
              }}
            />
          </BaseForm.Item>
        </Col>
        <Col flex={1}>
          <BaseForm.Item
            name="hoursPerWeek"
            label={"Horas de trabajo a la semana"}
            rules={[{ required: true, message: "Este campo es obligatorio" }]}
          >
            <InputNumber
              addonAfter="Hrs"
              min={1}
              max={84}
              style={{ width: "45%" }}
              onChange={(e) => {
                handleTimeFieldsChange("hoursPerWeek", e);
              }}
            />
          </BaseForm.Item>
        </Col>
        <Col ref={ref1}>
          <Statistic title="Proyección de horas totales" value={totalHours} />
        </Col>
        <Col>
          <InfoButton onClick={() => setOpenTour(true)} />
        </Col>
      </Row>
      <Space>
        <Tour
          open={openTour}
          onClose={()=> setOpenTour(false)}
          steps={steps}
          placement="top"
          arrow={false}
          scrollIntoViewOptions={{ block: "start", behavior: "smooth" }}
        />
      </Space>
    </>
  );
};
