import { Button, Popover, Table, Drawer } from "antd";
import React, { useEffect, useState } from "react";
import * as S from "../Employees.styles";
import { doList, doRemove } from "store/slices/employeeSlice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Employee } from "../types";
import { WorkCenter } from "../../workcenters/types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ColumnsType } from "antd/es/table";
import { filterProps } from "components/common/FilterProps";
import { initialValues } from "../types";
import { OptionsDrawerBar } from "../EmployeesDetail/OptionsDrawerBar";
import { EmployeeDetail } from "../EmployeesDetail/EmployeeDetail";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions"
import { EmployeesHeaderBtns } from "./EmployeesHeaderBtns";
const {writeEmployees} = PermissionsToAccess;

export const EmployeesList: React.FC = () => {
  const dispatch = useAppDispatch();
  const [emplyeeData, setEmplyeeData] = useState<Employee[]>();
  const { employees, status: empStatus } = useAppSelector((state) => state.employee);
  const [selectedRowData, setSelectedRowData] = useState<Employee>();

  
  // Drawer
  const [open, setOpen] = useState(false);
  const showDrawer = () => {
    setOpen(true);
  };
  const onCloseDrawer = () => {
    setSelectedRowData(initialValues);
    setOpen(false);
  };

  const onDeleteEmployee = async (data: any) => {
    const result = employees.filter((employee) => employee._id === data._id);
    await doRemove(result[0]);
    setEmplyeeData((pre) => {
      return pre!.filter((employee) => employee._id !== data._id);
    });

    return true;
  };
  useEffect(() => {
    if(empStatus !== 'fulfilled')
    dispatch(doList());
  }, [dispatch, empStatus]);
  useEffect(() => {
    setEmplyeeData(employees);
  }, [employees]);

  const columns: ColumnsType<Employee> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "index",
      sorter: (a: Employee, b: Employee) => a.name.localeCompare(b.name),
      ...filterProps,
      onFilter: (value, record) => {
        return record.name
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Puesto",
      dataIndex: "position",
      key: "position",
      sorter: (a: Employee, b: Employee) =>
      a.position && b.position
      ? a.position.localeCompare(b.position)
      : a.position
      ? a.position.localeCompare("")
      : b.position
      ? b.position.localeCompare("")
      : "".localeCompare(""),
      ...filterProps,
      onFilter: (value, record) => {
        return record.position
          ? record.position.toLowerCase().includes(value.toString().toLowerCase())
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Correo electrónico",
      dataIndex: "email",
      key: "email",
      sorter: (a: Employee, b: Employee) =>
      a.email && b.email
      ? a.email.localeCompare(b.email)
      : a.email
      ? a.email.localeCompare("")
      : b.email
      ? b.email.localeCompare("")
      : "".localeCompare(""),
      ...filterProps,
      onFilter: (value, record) => {
        return record.email
          ? record.email.toLowerCase().includes(value.toString().toLowerCase())
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Plantel",
      dataIndex: "workcenter",
      key: "companies",
      ...filterProps,
      render: (workcenter: WorkCenter) => `${workcenter?.nameShort ?? ""}`,
      onFilter: (value, record) => {
        return record.workcenter?.nameShort
          .toLowerCase()
          .includes(value.toString().toLowerCase()) || false;
      },
      sorter: (a, b) => a.workcenter && b.workcenter ? a.workcenter.nameShort.localeCompare(b.workcenter.nameShort) : -1,
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "ststatusatus",
      render: (data: boolean) => (data ? "Activo" : "Inactivo"),
      sorter: (a: Employee, b: Employee) => Number(a.status) - Number(b.status),
    },
    {
      title: "Acción",
      dataIndex: "",
      key: "x",
      align: "center",
      render: (data: Employee) => {
        return (
          <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Popover
              content={
                <Button
                  type="primary"
                  danger
                  block
                  onClick={() => {
                    onDeleteEmployee(data);
                  }}
                >
                  Confirmar
                </Button>
              }
              trigger="click"
              placement="left"
            >
              <FontAwesomeIcon icon={solid("trash")} style={{ color: "red" }} />
            </Popover>
          </span>
        );
      },
    },
  ];

  return (
    <>
      <S.Wrapper>
        <S.Card
          id="employees-list"
          title={`Colaboradores`}
          extra={<EmployeesHeaderBtns/>}
          padding="1.25rem"
        >
          <Drawer
            title="Detalle del colaborador"
            placement="right"
            width="50%"
            onClose={onCloseDrawer}
            open={open}
            extra={
              CheckAccess(writeEmployees) && (
                <OptionsDrawerBar
                  rowSelected={selectedRowData ?? initialValues}
                />
              )
            }
          >
            <EmployeeDetail selectedRow={selectedRowData ?? initialValues} />
          </Drawer>
          <Table
            dataSource={emplyeeData}
            size="middle"
            tableLayout="fixed"
            columns={
              CheckAccess(writeEmployees)
                ? columns
                : columns.filter((col) => col.title !== "Acción")
            }
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setSelectedRowData(record);
                  showDrawer();
                },
              };
            }}
            rowKey="_id"
          />
        </S.Card>
      </S.Wrapper>
    </>
  );
};
