import { httpApi } from "api/http.api";
import { Company } from "components/companies/types";

const controller = `statistic`;

export type CompanyStatistics = {
  _id: string;
  rejected: Number;
  active: Number;
  pending: Number;
  draft: Number;
  company: Company;
};
export type SubWorkCStatistics = {
  _id: string;
  rejected: Number;
  active: Number;
  pending: Number;
  draft: Number;
};

export const companiesStatistics = (): Promise<CompanyStatistics[]> =>
  httpApi
    .get<CompanyStatistics[]>(`${controller}/companiesStatistics`)
    .then(({ data }) => data);

export const subsystemWorkCStatistics = (): Promise<SubWorkCStatistics[]> =>
  httpApi
    .get<SubWorkCStatistics[]>(`${controller}/subsystemWorkCStatistics`)
    .then(({ data }) => data);

export const workCStatistics = (): Promise<SubWorkCStatistics[]> =>
httpApi
  .get<SubWorkCStatistics[]>(`${controller}/workCStatistics`)
  .then(({ data }) => data);
