export type Subsystem = {
  _id?: string;
  name: string;
  nameShort: string;
  principal: string;
};

export const initialValues: Subsystem = {
  name: "",
  nameShort: "",
  principal: "",
};
