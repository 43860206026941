import React, { useEffect, useState } from "react";
import { Empty, Modal as AntdModal, ModalProps as AntModalProps, Spin } from 'antd';
import { modalSizes } from "constants/modalSizes";
import { getFileBufferbyId, getFileUrlbyId } from "api/file.api";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { doGetFileStorageConfig } from "store/slices/configSlice";
import * as Sentry from "@sentry/react";
import { notificationController } from "controllers/notificationController";

export interface DocumentProps extends AntModalProps {
  fileId: string;
}

export const Document: React.FC<DocumentProps> = ({ fileId, ...props }) => {
  const dispatch = useAppDispatch();
  const { storageType, storageTypeStatus } = useAppSelector(
    (state) => state.config
  );
  const modalSize = Object.entries(modalSizes).find(
    (sz) => sz[0] === "large"
  )?.[1];
  const [filePath, setFilePath] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const fetchData = async (id: string) => {
    setIsLoading(true);
    try {
      const arrayBuffer = await getFileBufferbyId(fileId);
      const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);
      setFilePath(url);
      setIsLoading(false);
    } catch (error: any) {
      notificationController.error({
        message: "Error al obtener el archivo, por favor sube uno nuevo",
      });
      setFilePath(null);
      setIsLoading(false);
      Sentry.captureMessage(`not able to fetch file ${error.message}`);
    }
  };

  useEffect(() => {
    if (storageTypeStatus !== "fulfilled" || !storageType) {
      dispatch(doGetFileStorageConfig());
    }
    if (fileId) {
      getUrl(fileId, storageType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId, dispatch, storageType, storageTypeStatus]);

  const getUrl = async (fileId: string, storage: string) => {
    if(storage === "cloud") {
      const fileUrl = await getFileUrlbyId(fileId);
      setFilePath(fileUrl);
    }
    if(storage === "disk") {
      await fetchData(fileId)
    }
  };

  return (
    <AntdModal
    destroyOnClose
    getContainer={false}
    width={modalSize}
    onCancel={() => setFilePath(null)}
    onOk={() => setFilePath(null)}
    {...props}
    >
      <Spin spinning={isLoading}>
        {filePath && filePath !== "" ? (
        <iframe
          title="PDF"
          src={filePath}
          style={{ width: "600px", height: "500px" }}
        ></iframe>
      ) : (
        <Empty />
      )}
      </Spin>
    </AntdModal>
  );
};
