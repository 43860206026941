import React, { useState } from "react";
import { Alert, Button, Modal } from "antd";
import Dropzone from "react-dropzone";
import { getPresignedUrl } from "api/file.api";
import { readToken } from "services/localStorage.service";
import { notificationController } from "controllers/notificationController";

interface AWSFileUploaderPops {
  title: string
  once?: boolean;
  onUploadCompleted: (selectedFiles: string[]) => void;
  bucket: string;
}

export const AWSFileUploader: React.FC<AWSFileUploaderPops> = ({
  onUploadCompleted,
  title,
  once = false,
  bucket,
}) => {
  const [selectedFiles, setFiles]: any = useState<File[]>([]);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const accepted = {
    'pdf': ['.pdf'],
  }

  const onDrop = (acceptedFiles: any) => {
    setFiles(acceptedFiles);
  };

  const handlerUpload = async () => {
    setConfirmLoading(true);
    const filesUploaded = [];
    try {
      for await (const file of selectedFiles) {
        const preObject = await getPresignedUrl();
        const form = new FormData();
        form.append("file", file);
        if (preObject.storageType === "cloud") {
          await fetch(preObject.url, {
            method: "PUT",
            body: form,
          });
        }
        if (preObject.storageType === "disk") {
          form.append("bucket", bucket);
          const response = await fetch(preObject.url, {
            method: "PUT",
            body: form,
            headers: new Headers({
              Authorization: `Bearer ${readToken()}`,
            }),
          });
          if(!response.ok){
            const message = `An error occured: ${response.status}`;
            throw new Error(message);
        }
        }
        filesUploaded.push(preObject.fileId);
      }

      onUploadCompleted(filesUploaded);
      setConfirmLoading(false);
      setOpen(false);
    } catch (error) {
      setConfirmLoading(false);
      notificationController.error({
        message: "Error al subir archivo, por favor vuelve a intentar más tarde",
      });
    }
  };
  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
          return false;
        }}
      >
        Seleccionar Archivos PDF
      </Button>
      <Modal
        title={title ? title : `Adjuntar archivo`}
        open={open}
        onOk={handlerUpload}
        onCancel={() => {
          setOpen(false);
        }}
        confirmLoading={confirmLoading}
      >
        <Alert message="Solo se aceptan archivos en formato PDF" type="info" />
        <br></br>
        <Dropzone onDrop={onDrop} maxFiles={1} accept={accepted}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  block
                  type='dashed'
                  style={{ whiteSpace: "normal", height: "auto" }}
                >
                  Arrastre el archivo .pdf o de click aqui para seleccionarlo
                </Button>
              </div>
            </section>
          )}
        </Dropzone>
        <div
          style={{ marginTop: 20, border: "2px solid #1C6EA4", padding: 20 }}
        >
          <p style={{ marginBottom: 15 }}>Archivos Seleccionados:</p>
          <ul>
            {selectedFiles &&
              selectedFiles.map((file: File) => (
                <li key={file.name}>1 {file.name}</li>
              ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};
