import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Form, Modal, Space } from "antd";
import TextArea from "antd/es/input/TextArea";
import { notificationController } from "controllers/notificationController";
import { useAppDispatch } from "hooks/reduxHooks";
import { comment } from "../../api/agreements.api";
import { useState } from "react";
import { Agreement } from "./types";
import { doList } from "store/slices/agreementSlice";

type OptionComentsProps = {
  closeModal?: any;
  selectedRow: Agreement;
  visibleRegresar?: boolean;
};

export const CommentsOptions = ({
  closeModal,
  selectedRow,
  visibleRegresar = true,
}: OptionComentsProps) => {
  const dispatch = useAppDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [valuesTextArea, setValuesTextArea] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const handleOk = () => {
    setLoading(true);
    comment({
      id: selectedRow._id ?? "",
      comment: valuesTextArea,
    })
      .then(() => {
        notificationController.success({
          message: "Expediente Actualizado",
        });
        dispatch(doList());
      })
      .catch((error) => {
        notificationController.error({
          message: "Error ",
        });
      });

    setLoading(false);
    closeModal(false);
    setModalVisible(false);
  };
  const handleCancel = () => {
    setModalVisible(false);
  };
  const handleChange = (e: any) => {
    setValuesTextArea(e.target.value);
  };

  return (
    <>
      <Space>
        <Button
          key='back'
          type='primary'
          disabled={!visibleRegresar}
          style={{ display: visibleRegresar ? "initial" : "none" }}
          onClick={() => {
            closeModal(false);
          }}
        >
          Regresar
        </Button>
        <Button
          type='primary'
          style={{ backgroundColor: "#3fb618" }}
          icon={
            <FontAwesomeIcon icon={solid("plus")} style={{ marginRight: 10 }} />
          }
          onClick={() => {
            setModalVisible(true);
          }}
        >
          Agregar
        </Button>
        <Modal
          title='Ingresa tus comentarios'
          open={modalVisible}
          destroyOnClose
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button type='primary' onClick={handleOk} loading={loading}>
              Enviar
            </Button>,
            <Button type='primary' danger onClick={handleCancel}>
              Cancelar
            </Button>,
          ]}
        >
          <Form layout='vertical' style={{ marginTop: 25 }} onFinish={handleOk}>
            <Form.Item>
              <TextArea
                size='small'
                allowClear
                required
                onChange={handleChange}
              />
            </Form.Item>
          </Form>
        </Modal>
      </Space>
    </>
  );
};
