import { InputNumber } from "antd";
import TextArea from "antd/es/input/TextArea";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";

interface RotationFieldsProps {
  onChange: (field: string, value: any) => any;
}

export const RotationFields = ({ onChange }: RotationFieldsProps) => (
  <>
    <BaseForm.Item
      name="rotationTimes"
      label={"Número de puestos en los que rotará el estudiante"}
      rules={[{ required: true, message: "Este campo es obligatorio" }]}
    >
      <InputNumber min={0} onChange={(e) => onChange("rotationTimes", e)} />
    </BaseForm.Item>
    <BaseForm.Item
      name="rotationPositions"
      label={
        "Puestos en los que rotará el estudiante, Ingrese un puesto por linea"
      }
    >
      <TextArea
        rows={4}
        onChange={(e) => onChange("rotationPositions", e.target.value)}
      />
    </BaseForm.Item>
  </>
);
