import { Button, Col, Row } from "antd";
import { CheckAccess } from "checkAccess/CheckAccess";
import { AGREEMENTS_PATH } from "components/router/AppRouter";
import SkeletonButton from "antd/es/skeleton/Button";
import React, { MutableRefObject } from "react";
import { PermissionsToAccess } from "checkAccess/ConstPermissions";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/reduxHooks";
import { ExportAgreements } from "./ExportAgreements";

type AgreementHeaderProps = {
  newAgreementRef: MutableRefObject<null>;
  exportAgreementRef: MutableRefObject<null>;
  module: string;
}
export const AgreementListHeader = ({newAgreementRef, exportAgreementRef, module }:AgreementHeaderProps) => {
  const { writeAgreements, exportAgreements } = PermissionsToAccess;
  const navigate = useNavigate();
  const { status: agreementStateStatus } = useAppSelector((state) => state.agreement);
  return (
    <Row gutter={12}>
      {CheckAccess(writeAgreements) && module==='agreement' && [
        <Col>
          <Button
            type='primary'
            ref={newAgreementRef}
            onClick={() => {
              navigate(`${AGREEMENTS_PATH}/nuevo`);
            }}
          >
            Nuevo Expediente
          </Button>
          ,
        </Col>,
      ]}
      {CheckAccess(exportAgreements) && (
              agreementStateStatus === "fulfilled" ? (
                <Col ref={exportAgreementRef}>
                  <ExportAgreements module={module}/>
                </Col>
              ) : (
                <Col>
                  <SkeletonButton block={true} />
                </Col>
                
              )
            )}
    </Row>
  );
};