import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { list, register, remove, update } from "api/subsystem.api";
import { Subsystem } from "components/subsystems/types";

export interface SubsystemSlice {
  subsystems: Subsystem[];
  status: "empty" | "fulfilled" | "error" | "updating";
}

const initialState: SubsystemSlice = {
  subsystems: [],
  status: "empty",
};

export const doRegister = createAsyncThunk(
  "subsystem/register",
  async (SubsystemPayload: Subsystem) =>
    register(SubsystemPayload).then((res) => res)
);

export const doList = createAsyncThunk("subsystem/list", async () =>
  list().then((res) => res)
);

export const doUpdate = createAsyncThunk(
  "subsystem/update",
  async (SubsystemPayload: Subsystem) => update(SubsystemPayload).then((res) => res)
);

export const doRemove = async (SubsystemPayload: Subsystem) =>
  remove(SubsystemPayload).then((res) => res);

export const doClear = createAction("subsystem/clear", () => {
  return {
    payload: "clear",
  };
});

const subsystemSlice = createSlice({
  name: "subsystem",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doRegister.fulfilled, (state, action) => {
      state.subsystems.push(action.payload);
      state.status = "updating";
    });
    builder.addCase(doList.fulfilled, (state, action) => {
      state.subsystems = action.payload;
      state.status = "fulfilled";
    });
    builder.addCase(doUpdate.fulfilled, (state, action) => {
      const index = state.subsystems.findIndex(r => r._id === action.payload._id);
      state.subsystems[index] = action.payload;
    });
    builder.addCase(doClear, (state, action) => {
      state.subsystems = [];
      state.status = "empty";
    });
  },
});

export default subsystemSlice.reducer;
