import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { list, create, remove, update, statusUpdate, UpdateObject } from "api/reps.api";
import { Representative, UpdateStatusCompanyProps } from "../../components/companies/types";

export interface RepresentativeSlice {
  representatives: Representative[];
  status: 'empty' | 'fulfilled' | 'error' | 'updating'
}

const initialState: RepresentativeSlice = {
  representatives: [],
  status: 'empty'
};

export const doRegister = createAsyncThunk(
  "representative",
  async (RepresentativePayload: Representative) => create(RepresentativePayload).then((res) => res)
);

export const doList = createAsyncThunk("representative/list", async () =>
  list().then((res) => res)
);

export const doRemove = createAsyncThunk("representative/remove", async (RepresentativePayload: Representative) =>
  remove(RepresentativePayload).then((res) => res)
);

export const doUpdate = createAsyncThunk("representative/update", async (RepresentativePayload: UpdateObject) =>
  update(RepresentativePayload).then((res) => res)
);

export const doUpdateStatus = createAsyncThunk(
  "representative/updateStatus",
  async (status: UpdateStatusCompanyProps) => statusUpdate(status).then((res) => res)
);

export const doClear = createAction('representative/clear', () => {
  return {
    payload: "clear",
  };
});

const representativeSlice = createSlice({
  name: "representative",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doRegister.fulfilled, (state, action) => {
      state.representatives.push(action.payload);
    });
    builder.addCase(doList.fulfilled, (state, action) => {
      state.representatives = action.payload;
      state.status = 'fulfilled';
    });
    builder.addCase(doUpdate.fulfilled, (state, action) => {
      const index = state.representatives.findIndex(r => r._id === action.payload._id);
      state.representatives[index] = action.payload;
    });
    builder.addCase(doUpdateStatus.fulfilled, (state, action) => {
      const index = state.representatives.findIndex(r => r._id === action.payload._id);
      state.representatives[index] = action.payload;
    });
    builder.addCase(doRemove.fulfilled, (state, action) => {
      state.status = 'updating';
      const index = state.representatives.findIndex(r => r._id === action.payload._id);
      delete state.representatives[index];
    });
    builder.addCase(doClear, (state, action) => {
      state.representatives = [];
      state.status = "empty";
    });
  },
});

export default representativeSlice.reducer;
