import { Button, Popover, Space, Modal, Form, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Agreement } from "components/Agreements/types";
import TextArea from "antd/es/input/TextArea";
import { nextApprovalStep, statusUpdate } from "api/agreements.api";
import { notificationController } from "controllers/notificationController";
import { doList } from "store/slices/agreementSlice";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Link } from "react-router-dom";
import { AGREEMENTS_PATH } from "components/router/AppRouter";
import { doGetApprovalProcess } from "store/slices/configSlice";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions"
import { IsAdultStudent } from "../utils/utils";
import { PermissionsType } from "components/employees/types";
import { RangePickerProps } from "antd/es/date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const {writeAgreements, editApprovedAgreements} = PermissionsToAccess

type OptionProps = {
  rowSelected: Agreement;
  closeDrawer: any;
  onDelete?: (data: Agreement) => any;
};
export const OptionsDrawerBar = ({ rowSelected, closeDrawer, onDelete }: OptionProps) => {
  const dispatch = useAppDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [nextRevisionBtnVisible, setnextRevisionBtnVisible] = useState(false);
  const [approveBtnVisible, setApproveBtnVisible] = useState(false);
  const [rejectBtnVisible, setRejectBtnVisible] = useState(false);
  const [inactiveBtnVisible, setInactiveBtnVisible] = useState(false);
  const [valuesTextArea, setValuesTextArea] = useState<string>("");
  const [newStatus, setNewStatus] = useState<string>("");
  const [inactiveDate, setinactiveDate] = useState<undefined | Date>(undefined);
  const [editRoute, setEditRoute] = useState<string>("edit");
  const [editPermission, setEditPermission] = useState<PermissionsType>(writeAgreements);
  const [loading, setLoading] = useState(false);
  const { user } = useAppSelector((state) => state.user);
  const hasWriteAccess = CheckAccess(writeAgreements);

  //config for approval roles
  const {validApprovalRoles, configStatus }  = useAppSelector((state) => state.config);

  useEffect(() => {
    if (configStatus !== "fulfilled") {
      dispatch(doGetApprovalProcess());
    }
      showNextRevisionBtn();
      showRejectBtn();
      showApproveBtn();
      showInactiveBtn();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, validApprovalRoles, configStatus, rowSelected]);

  useEffect(() => {
    if (rowSelected && rowSelected.approvalStep !== undefined) {
      showNextRevisionBtn();
      showRejectBtn();
      showApproveBtn();
      showInactiveBtn();
    }
    if(rowSelected && rowSelected.status) {
      checkEditAgreement();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[rowSelected]);

  const isAgreementComplete = ()=> {
    const agreementSelected = {
      sequence: rowSelected.sequence,
      studentId: rowSelected.studentId,
      workCenterId: rowSelected.workCenterId,
      companyId: rowSelected.companyId,
      representativeId: rowSelected.representativeId,
      legalRepresentativeId: rowSelected.legalRepresentativeId,
      startDate: rowSelected.startDate,
      endDate: rowSelected.endDate,
      status: rowSelected.status,
      learningAgreementFileId: rowSelected.learningAgreementFileId,
      colaborationAgreementFileId: rowSelected.colaborationAgreementFileId,
      tutorIdFileId: rowSelected.tutorIdFileId,
      tutorAuthLetterFileId: rowSelected.tutorAuthLetterFileId,
      insuranceFileId: rowSelected.insuranceFileId,
      rotationPlanFileId: rowSelected.rotationPlanFileId,
      learningPositionsFieldId: rowSelected.learningPositionsFieldId,
      matrixFieldId: rowSelected.matrixFieldId,
      principalFileId: rowSelected.principalFileId,
      principal: rowSelected.principal,
      principalPosition: rowSelected.principalPosition,
      rotationTimes: rowSelected.rotationTimes,
      rotationPositions: rowSelected.rotationPositions,
      hoursPerWeek: rowSelected.hoursPerWeek,
    }
    let requiredFields = [
      'studentId',
      'workCenterId',
      'companyId',
      'representativeId',
      'legalRepresentativeId',
      'startDate',
      'endDate',
      'status',
      'sequence',
      'learningAgreementFileId',
      'colaborationAgreementFileId',
      'tutorAuthLetterFileId',
      'tutorIdFileId',
      'insuranceFileId',
      'rotationPlanFileId',
      'learningPositionsFieldId',
      'matrixFieldId',
      'principalFileId',
      'principalPosition',
      'rotationPositions',
      'principal',
      'rotationTimes',
      'hoursPerWeek',
    ];
    if (IsAdultStudent(rowSelected.studentId)){
      requiredFields = [
        'studentId',
        'workCenterId',
        'companyId',
        'representativeId',
        'legalRepresentativeId',
        'startDate',
        'endDate',
        'status',
        'sequence',
        'learningAgreementFileId',
        'colaborationAgreementFileId',
        'tutorIdFileId',
        'insuranceFileId',
        'rotationPlanFileId',
        'learningPositionsFieldId',
        'matrixFieldId',
        'principalFileId',
        'principalPosition',
        'rotationPositions',
        'principal',
        'rotationTimes',
        'hoursPerWeek',
      ];
    }
    const missingFields = requiredFields.length - Object.values(agreementSelected).filter(Boolean).length
    return missingFields > 0 ? false : true
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current > dayjs().endOf('day');
  };

  const checkEditAgreement = () => {
    if(rowSelected.status === "ACTIVO" ){
      setEditPermission(editApprovedAgreements);
      setEditRoute("editapproved");
    } else{
      setEditPermission(writeAgreements);
      setEditRoute("edit");
    }
  }

  const showNextRevisionBtn = () => {
    if(rowSelected.status === "ACTIVO" || rowSelected.status === "INACTIVO") {
      setnextRevisionBtnVisible(false);
      return false;
    }
    if (JSON.stringify(rowSelected.approvalStep) === JSON.stringify(validApprovalRoles[0])){
      setnextRevisionBtnVisible(false);
      return false;
    }
    if (!isAgreementComplete()) {
      setnextRevisionBtnVisible(false);
      return false;
    }
    if (user?.role && user?.role ===1 ) {
      setnextRevisionBtnVisible(true);
      return true;
    }
    if (JSON.stringify(user?.approvementRole) === JSON.stringify(rowSelected.approvalStep)) {
      setnextRevisionBtnVisible(true);
      return true;
    }
    if ((!user?.approvementRole || !user?.approvementRole.name) && (rowSelected.status==="BORRADOR" || !rowSelected.approvalStep)) {
      setnextRevisionBtnVisible(true);
      return true;
    }
  }
  const showRejectBtn = () => {
    if(rowSelected.status === "ACTIVO" || rowSelected.status === "INACTIVO" || rowSelected.status === "RECHAZADO") {
      setRejectBtnVisible(false);
      return false
    }
    if (user?.role && user?.role ===1 ) {
      setRejectBtnVisible(true);
      return true;
    }
    if (JSON.stringify(user?.approvementRole) === JSON.stringify(rowSelected.approvalStep)) {
      setRejectBtnVisible(true);
      return true;
    }
    setRejectBtnVisible(false);
    return false;
  }
  const showApproveBtn = () => {
    if(rowSelected.status === "ACTIVO" || rowSelected.status === "INACTIVO") {
      setApproveBtnVisible(false);
      return false
    }
    if (!isAgreementComplete()) {
      setApproveBtnVisible(false);
      return false;
    }
    if (
      JSON.stringify(user?.approvementRole) === JSON.stringify(rowSelected.approvalStep) &&
      JSON.stringify(rowSelected.approvalStep) === JSON.stringify(validApprovalRoles[0])
    ) {
      setApproveBtnVisible(true);
      return true;
    }
    if (user?.role && user?.role ===1 &&  JSON.stringify(rowSelected.approvalStep) === JSON.stringify(validApprovalRoles[0])) {
      setApproveBtnVisible(true);
      return true;
    }
    setApproveBtnVisible(false);
    return false;
  }
  const showInactiveBtn = () => {
    if(user?.role === 1 && rowSelected.status === "ACTIVO") {
      setInactiveBtnVisible(true);
    return true;
    }
    setInactiveBtnVisible(false);
    return false
  };
  const handleDelete = () => {
    if(onDelete) {
      onDelete(rowSelected);
      closeDrawer(false);
    }
  }
  const handleOk = (newStatus: string) => {
    if(newStatus==="INACTIVO" && !inactiveDate) {
      notificationController.warning({
        message: "Selecciona una fecha de baja ",
      });
      return ;
    }
    if(newStatus==="INACTIVO" && (!rowSelected.hoursPerWeek || rowSelected.hoursPerWeek <= 0 || !rowSelected.startDate)) {
      notificationController.warning({
        message: "No puedes dar de baja un convenio que no tiene número de horas a la semana o fecha de inicio",
      });
      return ;
    }
    if((newStatus==="INACTIVO" || newStatus === "RECHAZADO") && !valuesTextArea) {
      notificationController.warning({
        message: "Escribe un motivo de baja o rechazo",
      });
      return ;
    }
    statusUpdate({
      id: rowSelected._id ?? "",
      comment: valuesTextArea,
      status: newStatus,
      approvalStep: rowSelected.approvalStep,
      currentStatus: rowSelected.status,
      inactiveDate: inactiveDate,
    })
      .then(() => {
        notificationController.success({
          message: "Expediente Actualizado",
        });
        dispatch(doList());
        setModalVisible(false);
        closeDrawer(false);
      })
      .catch((error) => {
        if(newStatus==="INACTIVO") {
          notificationController.error({
            message: "Error al dar de baja",
            description: "Revisa que la fecha de baja y la cantidad de horas a la semana sean correctas"
          });
        }
        notificationController.error({
          message: "Error ",
        });
        setModalVisible(false);
      });
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const handleChange = (e: any) => {
    setValuesTextArea(e.target.value);
  };
  const approveAgreement = (e: any) => {
    setLoading(true);
    if (rowSelected.companyId.status !== "Verificada" || rowSelected.representativeId.status !== "Verificada") {
      notificationController.error({
        message: "Error ",
        description: "Un expediente no puede ser aprobado si el representante o la empresa no se han verificado",
      });
      setLoading(false);
      return false;
    }
    statusUpdate({
      id: rowSelected._id ?? "",
      comment: "Aprobado",
      status: "ACTIVO",
      approvalStep: rowSelected.approvalStep,
      currentStatus: rowSelected.status,
    })
      .then(() => {
        notificationController.success({
          message: "Expediente Actualizado",
        });
        dispatch(doList());
        setModalVisible(false);
        closeDrawer(false);
        setApproveBtnVisible(false);
      })
      .catch((error) => {
        notificationController.error({
          message: "Error ",
          description: "Error al aprobar expediente",
        });
        setModalVisible(false);
      });
    setLoading(false);
  };

  const nextRevision = (e: any) => {
    nextApprovalStep(rowSelected._id ?? "")
      .then(() => {
        notificationController.success({
          message: "expediente enviado a revisión",
        });
        dispatch(doList());
        setModalVisible(false);
        closeDrawer(false);
        setnextRevisionBtnVisible(false);
      })
      .catch((error) => {
        notificationController.error({
          message: "Error ",
        });
        setModalVisible(false);
      });
  };
  return (
    <>
      <Space>
        <>  { hasWriteAccess && onDelete && (
            <Popover style={{display: "block"}}
            content={
              <Button
                type="primary"
                danger
                block
                onClick={handleDelete}
              >
                Eliminar
                {/* falta testear que todo funcione bien al eliminar  */}
              </Button>
            }
            trigger="click"
            placement="left"
          > <Button
          type="primary"
          danger
          block
        >
          <FontAwesomeIcon icon={solid("trash")} style={{ color: "white"}} />
        </Button>
          </Popover>
        )}
            {approveBtnVisible &&(
              <Popover
                content={
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#329213" }}
                    block
                    onClick={approveAgreement}
                  >
                    Confirmar
                  </Button>
                }
                trigger="click"
              >
                <Button
                  type="primary"
                  style={{ backgroundColor: "#3fb618" }}
                  block
                >
                  Aprobar
                </Button>
              </Popover>
            )}
            {rejectBtnVisible && (
              <Button
                danger
                block
                onClick={() => {
                  setModalVisible(true);
                  setNewStatus("RECHAZADO");
                }}
              >
                Rechazar
              </Button>
            )}
            {inactiveBtnVisible && (
            <Popover
              content={
                <Button
                  type="primary"
                  block
                  onClick={() => {
                    setModalVisible(true);
                    setNewStatus("INACTIVO");
                  }}
                >
                  Confirmar
                </Button>
              }
              trigger="click"
            >
              <Button
                type="primary"
                style={{ backgroundColor: "#69707a" }}
                block
              >
                Dar de baja
              </Button>
            </Popover>
          )}
          </>
        {nextRevisionBtnVisible && (
          <>
            <Popover
              content={
                <Button
                  type='primary'
                  style={{ backgroundColor: "#FF8C00" }}
                  block
                  onClick={nextRevision}
                >
                  Confirmar
                </Button>
              }
              trigger="click"
            >
              <Button
                type="primary"
                style={{ backgroundColor: "#FF8C00" }}
                block
              >
                Enviar a revisión
              </Button>
            </Popover>
          </>
        )}
       {CheckAccess(editPermission) && (
          <Link to={`${AGREEMENTS_PATH}/${editRoute}/${rowSelected._id}`}>
            <Button type="primary">Editar</Button>
          </Link>
        )}
      </Space>
      <Modal
        title="Motivo de rechazo o baja"
        open={modalVisible}
        destroyOnClose
        onOk={() => handleOk(newStatus)}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" danger onClick={handleCancel}>
            Cancelar
          </Button>,
          <Button
            type="primary"
            onClick={() => handleOk(newStatus)}
            style={{ backgroundColor: "#3fb618" }}
            loading={loading}
          >
            Guardar
          </Button>,
        ]}
      >
        <Form
          layout="vertical"
          style={{ marginTop: 25 }}
          onFinish={() => handleOk(newStatus)}
        >
          <Form.Item
            label="Motivo"
            rules={[{ required: true, message: "Este campo es obligatorio" }]}
          >
            <TextArea
              placeholder="Ingresa el motivo de rechazo o baja del expediente"
              size="small"
              allowClear
              required
              onChange={handleChange}
            />
          </Form.Item>
        { newStatus === "INACTIVO" && <Form.Item
            name="inactiveDate"
            label={"Fecha de baja"}
            rules={[{ required: true, message: "Este campo es obligatorio" }]}
          >
            <DatePicker
              onChange={(e) => setinactiveDate(e!.toDate())}
              disabledDate={disabledDate}
            />
          </Form.Item>}
        </Form>
      </Modal>
    </>
  );
};
