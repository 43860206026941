import { Card, Descriptions, Empty, Spin } from "antd";
import { ReactNode, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Agreement } from "components/Agreements/types";
import { WorkCenter } from "components/workcenters/types";
import { Student, gradesString, DescritpionProps } from "../types";
import { getEdad, getHoursMinutesString, valueDate } from "hooks/dateTreatment";
import { colorStatus } from "../Student.styles";
import { AgreementsList } from "components/dashboard/AgreementsList";
import { doList as agreementsDoList } from "store/slices/agreementSlice";
import { CommentsStudent } from "./CommentsStudent";
import { CommentsOptions } from "./CommentsOptions";
import { colorCompletedHours } from "components/Agreements/Agreements.styles";
import { Document } from "components/common/Document/Document";
import { ShowDocumentBtn } from "components/common/Document/ShowDocumentBtn";

type StudentDetailProps = {
  selectedRow: Student;
};

export const StudentDetail = ({ selectedRow }: StudentDetailProps) => {
  const dispatch = useAppDispatch();
  //agreements
  const { agreements, status } = useAppSelector((state) => state.agreement);
  const [studentAgreements, setStudentAgreements] = useState<Agreement[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showLeaveMotive, setShowLeaveMotive] = useState<boolean>(false);
  // document
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState("");

  const filterAgreementByStudent = () => {
    setStudentAgreements(
      agreements.filter((agre) => {
        return agre.studentId._id === selectedRow._id;
      })
    );
  };

  useEffect(() => {
    if (
      selectedRow.leaveMotive &&
      (selectedRow.academicStatus === "Baja temporal" ||
        selectedRow.academicStatus === "Baja definitiva")
    ) {
      setShowLeaveMotive(true);
    } else {
      setShowLeaveMotive(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status !== "fulfilled") {
      setIsLoading(true);
      dispatch(agreementsDoList());
    } else {
      filterAgreementByStudent();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, status, agreements]);

  //workcenter data
  const workCenterData = (item: WorkCenter): ReactNode => {
    return (
      <>
        <Descriptions bordered size="small" title="Plantel">
          <Descriptions.Item span={3} key="subsystem" label="Subsistema">
            {item.subsystem ?? "No hay Registro"}
          </Descriptions.Item>
          <Descriptions.Item span={3} key="workName" label="Plantel">
            {item.nameShort ?? ""}
          </Descriptions.Item>
          <Descriptions.Item
            span={3}
            key="directorSubsystem"
            label="Director(a) general del subsistema"
          >
            {item.currentSubsystemPrincipal ?? ""}
          </Descriptions.Item>
        </Descriptions>
      </>
    );
  };

  return (
    <>
      <Descriptions {...DescritpionProps} size="small">
        <Descriptions.Item key="name" label="Nombre(s)" span={1}>
          {`${selectedRow.name}`}
        </Descriptions.Item>
        <Descriptions.Item key="name" label="Apellido paterno" span={1}>
          {`${selectedRow.paternalLastname ?? ""}`}
        </Descriptions.Item>
        <Descriptions.Item key="name" label="Apellido materno" span={1}>
          {`${selectedRow.maternalLastname ?? ""}`}
        </Descriptions.Item>
        <Descriptions.Item key="status" label="Estado académico">
          <p style={{ color: colorStatus(selectedRow.academicStatus) }}>
            {selectedRow.academicStatus ?? "Sin registro"}
          </p>
        </Descriptions.Item>
        <Descriptions.Item key="CURP" label="CURP">
          {selectedRow.curp}
        </Descriptions.Item>
        <Descriptions.Item key="gender" label="Género">
          {selectedRow.gender ?? ""}
        </Descriptions.Item>
        <Descriptions.Item key="edad" label="Edad y Fecha de nacimiento">
          {selectedRow.birthDate
            ? `${getEdad(selectedRow.birthDate)} Años - ${valueDate(
                selectedRow.birthDate,
                false
              )}`
            : "No ingresada"}
        </Descriptions.Item>
        <Descriptions.Item key="completionDate" label="Fecha de Graduacion">
          {selectedRow.completionDate
            ? valueDate(selectedRow.completionDate)
            : "No ingresada"}
        </Descriptions.Item>
        <Descriptions.Item span={3} key="email" label="Correo electrónico">
          {selectedRow.email}
        </Descriptions.Item>
        <Descriptions.Item key="phone" label="Teléfono">
          {selectedRow.phone ? selectedRow.phone : "Sin teléfono Registrado"}
        </Descriptions.Item>
        <Descriptions.Item key="career" label="Carrera">
          {selectedRow.career}
        </Descriptions.Item>
        <Descriptions.Item key="grade" label="Periodo escolar">
          {selectedRow.grade} - {gradesString[selectedRow.grade ?? 0]}
        </Descriptions.Item>
        <Descriptions.Item key="tutor" label="Nombre del Responsable">
          {selectedRow.tutor}
        </Descriptions.Item>
        <Descriptions.Item key="completedHours" label="Horas completadas">
          <p
            style={colorCompletedHours(
              selectedRow.completedHours ? selectedRow.completedHours.hours : 0
            )}
          >
            {selectedRow.completedHours
              ? getHoursMinutesString(selectedRow.completedHours)
              : "Sin registro"}
          </p>
        </Descriptions.Item>
        {selectedRow.completedHours &&
          selectedRow.completedHours.hours >= 1000 && (
            <Descriptions.Item
              key="certificateFileId"
              label="Certificado de finalización"
            >
              {selectedRow.certificateFileId &&
              selectedRow.certificateFileId !== "" ? (
                <ShowDocumentBtn
                  label="Certificado de finalización"
                  onClick={() => {
                    setPdfViewerId(selectedRow.certificateFileId as string);
                    setShowDocument(true);
                  }}
                />
              ) : (
                <>No se ha cargado el Certificado de finalización</>
              )}
            </Descriptions.Item>
          )}
        {showLeaveMotive && (
          <Descriptions.Item key="leaveMotive" label="Motivo de baja">
            {selectedRow.leaveMotive ?? ""}
          </Descriptions.Item>
        )}
      </Descriptions>
      <Document
        fileId={pdfViewerId}
        open={showDocument}
        onCancel={() => setShowDocument(false)}
        onOk={() => setShowDocument(false)}
      />
      <br />
      {selectedRow.workcenter
        ? workCenterData(selectedRow.workcenter)
        : "Contacta al Administrador"}
      <br />
      <Card title={`Expedientes del alumno`}>
        <Spin spinning={isLoading}>
          {studentAgreements && studentAgreements.length > 0 ? (
            <AgreementsList agreements={studentAgreements}></AgreementsList>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}></Empty>
          )}
        </Spin>
      </Card>
      <br />
      <Descriptions
        bordered
        size="small"
        title="Comentarios"
        extra={
          <CommentsOptions selectedRow={selectedRow} visibleRegresar={false} />
        }
      ></Descriptions>
      {selectedRow.comments && <CommentsStudent selectedRow={selectedRow} />}
    </>
  );
};
