import React, { useEffect, useState } from "react";
import * as S from "./Company.styles";
import { Company, initialValues, Representative } from "./types";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch } from "hooks/reduxHooks";
import { Button, Divider, Form, Input } from "antd";
import { doUpdate } from "store/slices/companySlice";
import { notificationController } from "controllers/notificationController";
import { AWSFileUploader } from "components/common/FileManager/AWSFileUploader";
import { RepsAdd } from "./RepsAdd";
import { RepsList } from "./RepsList";
import { Document } from "components/common/Document/Document";
import { COMPANY_PATH } from "components/router/AppRouter";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { ShowDocumentBtn } from "components/common/Document/ShowDocumentBtn";

interface CompanyEditProps {
  company: Company;
  representatives: Representative[]
  contacts: Representative[]
}

export const CompanyEdit = ({ company, representatives, contacts }: CompanyEditProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const incFileIdHook = Form.useWatch('incFileId', form);
  const fiscalFileIdHook = Form.useWatch('fiscalFileId', form);
  const [isLoading, setLoading] = useState(false);
  const [showAddRep, setShowAddRep] = useState(false);
  const [showAddContact, setShowAddContact] = useState(false);
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState("");
  // track changes
  const [filesChanges, setfilesChanges] = useState<any>({})

  useEffect(() => {
    form.setFieldValue('_id', company._id);
    form.setFieldValue('name', company.name);
    form.setFieldValue('commercialName', company.commercialName);
    form.setFieldValue('taxId', company.taxId);
    form.setFieldValue('industry', company.industry);
    form.setFieldValue('incFileId', company.incFileId);
    form.setFieldValue('fiscalFileId', company.fiscalFileId);
  },[form, company])

  const handleSubmit = (values: Company) => {
    setLoading(true);
    let changedValues = form.getFieldsValue(true, meta => meta.touched);
    changedValues = {...changedValues, ...filesChanges}
    const sendPayload = {
      changedValues,
      company: values
    }
    dispatch(doUpdate(sendPayload))
      .unwrap()
      .then((companySaved) => {
        notificationController.success({
          message: "Se editó la empresa correctamente",
        });
        navigate(COMPANY_PATH);
      })
      .catch((err) => {
        notificationController.error({
          message: "Error al editar la empresa",
        });
        setLoading(false);
      });
  };

  return (
    <BaseForm
        form={form}
        layout='vertical'
        onFinish={handleSubmit}
        requiredMark='optional'
        initialValues={initialValues}
      >
        <BaseForm.Item name="_id" hidden={true} />
        <BaseForm.Item
          name='name'
          key='name'
          label={`Razón social`}
          rules={[{ required: true, message: "Este campo es obligatorio" }]}
        >
          <Input placeholder='Ingrese la razón social de la empresa' />
        </BaseForm.Item>
        <BaseForm.Item
          name='commercialName'
          key='commercialName'
          label={`Nombre comercial`}
          rules={[{ required: false, message: "Este campo es obligatorio" }]}
        >
          <Input placeholder='Ingrese el nombre comercial de la empresa' />
        </BaseForm.Item>
        <BaseForm.Item
          name='taxId'
          key='taxId'
          label={`RFC`}
        >
          <Input placeholder='Ingrese el RFC para verificar los datos fiscales' />
        </BaseForm.Item>
        <BaseForm.Item
          name='industry'
          key='industria'
          label={`Sector productivo`}
        >
          <Input placeholder='Sector productivo (ej, construccion, hoteleria, etc)' />
        </BaseForm.Item>
        <div>
          <Divider orientation="left">
            Representantes de la empresa
          </Divider>
          <RepsList reps={representatives} showAddRep={showAddRep} editMode idCompany={company} type={"Representative"}/>
          <Button
            type='dashed'
            size='small'
            style={{ color: "#006400", marginBottom: `20px` }}
            onClick={() => setShowAddRep(true)}
            htmlType='button'
          >
            Registrar Representante
          </Button>
          <RepsAdd open={showAddRep} companyId={company._id || ''} onClose={() => setShowAddRep(false)} type={"Representative"} />
        </div>
        <div>
          <Divider orientation="left">
            Contactos de la empresa
          </Divider>
          <RepsList reps={contacts} showAddRep={showAddRep} editMode idCompany={company} type={"Contact"}/>
          <Button
            type='dashed'
            size='small'
            style={{ color: "#006400", marginBottom: `20px` }}
            onClick={() => setShowAddContact(true)}
            htmlType='button'
          >
            Registrar Contacto de la empresa
          </Button>
          <RepsAdd open={showAddContact} companyId={company._id || ''} onClose={() => setShowAddContact(false)} type={"Contact"} />
        </div>
        <BaseForm.Item label={`Acta Constitutiva`} name='incFileId'>
          { company.incFileId && company.incFileId !== "" && (
            <ShowDocumentBtn
            label="ver documento original"
            onClick={() => {
              setPdfViewerId(company.incFileId as string);
              setShowDocument(true);
            }}
          />
          )}
          { incFileIdHook !== company.incFileId && (
            <>
              <FontAwesomeIcon
                icon={solid("circle-arrow-right")}
                style={{ color: "#ce0e2c", marginRight: '5px' }}
              />
              <ShowDocumentBtn
                onClick={() => {
                  setPdfViewerId(incFileIdHook);
                  setShowDocument(true);
                }}
                label="ver nuevo documento"
              />
            </>
          ) }
          <AWSFileUploader
            title="Acta Constitutiva"
            bucket="company-inc"
            onUploadCompleted={(id: string[]) => {
              form.setFieldValue("incFileId", id[0]);
              setfilesChanges({...filesChanges, incFileId: id[0]})
            }}
            once
          />
        </BaseForm.Item>
        <BaseForm.Item label={`Constancia de Situación Fiscal`} name='fiscalFileId'>
        { company.fiscalFileId  && company.fiscalFileId !== ""  && (
            <ShowDocumentBtn
            label='ver documento original'
            onClick={() => {
              setPdfViewerId(company.fiscalFileId as string);
              setShowDocument(true);
            }}
          />
          )}
          { fiscalFileIdHook !== company.fiscalFileId && (
            <>
              <FontAwesomeIcon
                icon={solid("circle-arrow-right")}
                style={{ color: "#ce0e2c", marginRight: '5px' }}
              />
              <ShowDocumentBtn
                label="ver nuevo documento"
                onClick={() => {
                  setPdfViewerId(fiscalFileIdHook);
                  setShowDocument(true);
                }}
              />
            </>
          ) }
          <AWSFileUploader
            title="Constancia de Situación Fiscal"
            bucket="company-fiscal"
            onUploadCompleted={(id: string[]) => {
              form.setFieldValue("fiscalFileId", id[0]);
              setfilesChanges({...filesChanges, fiscalFileId: id[0]})
            }}
            once
          />
        </BaseForm.Item>
        <BaseForm.Item noStyle>
          <S.SubmitButton
            type='primary'
            htmlType='submit'
            size='large'
            loading={isLoading}
          >
            {`Actualizar`}
          </S.SubmitButton>
        </BaseForm.Item>

        <Document
            fileId={pdfViewerId}
            open={showDocument}
            onCancel={() => setShowDocument(false)}
            onOk={() => setShowDocument(false)}
          />
      </BaseForm>
  );
};
