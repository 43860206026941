import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BaseForm } from 'components/common/forms/BaseForm/BaseForm';
import { useAppDispatch } from 'hooks/reduxHooks';
import { doLogin } from 'store/slices/authSlice';
import { notificationController } from 'controllers/notificationController';
import * as S from './LoginForm.styles';
import * as Auth from 'components/layouts/AuthLayout/AuthLayout.styles';
/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro' */

interface LoginFormData {
  email: string;
  password: string;
}

export const initValues: LoginFormData = {
  email: '',
  password: '',
};

export const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: LoginFormData) => {
    setLoading(true);
    dispatch(doLogin(values))
      .unwrap()
      .then(() => navigate('/'))
      .catch((err) => {
        notificationController.error({ message: "Sus credenciales son incorrectas" });
        setLoading(false);
      });
  };

  return (
    <Auth.FormWrapper>
      <BaseForm layout="vertical" onFinish={handleSubmit} requiredMark="optional" initialValues={initValues}>
        <Auth.FormTitle>{`Ingresar`}</Auth.FormTitle>
        <S.LoginDescription>{`Le damos la bienvenida a la Plataforma de Registro y Control de Estudiantes de Educación Dual, para iniciar la navegación ingrese los datos solicitados`}</S.LoginDescription>
        <Auth.FormItem
          name="email"
          label={`Email`}
          rules={[
            { required: true, message: `Campo obligatorio` },
            {
              type: 'email',
              message: `Dirección no valida`,
            },
          ]}
        >
          <Auth.FormInput placeholder={`Email`} />
        </Auth.FormItem>
        <Auth.FormItem
          label={`Contraseña`}
          name="password"
          rules={[{ required: true, message: `Campo obligatorio`}]}
        >
          <Auth.FormInputPassword placeholder={`Contraseña`} />
        </Auth.FormItem>
        <Auth.ActionsWrapper>
          <BaseForm.Item name="rememberMe" valuePropName="checked" noStyle>
            <Auth.FormCheckbox>
              <S.RememberMeText>{`Recordar cuenta`}</S.RememberMeText>
            </Auth.FormCheckbox>
          </BaseForm.Item>
          <Link to="/auth/forgot-password">
            <S.ForgotPasswordText>{`Recuperar contraseña`}</S.ForgotPasswordText>
          </Link>
        </Auth.ActionsWrapper>
        <BaseForm.Item noStyle>
          <Auth.SubmitButton type="primary" htmlType="submit" loading={isLoading}>
            {`Ingresar`}
          </Auth.SubmitButton>
        </BaseForm.Item>
{/*         <BaseForm.Item noStyle>
          <Auth.SocialButton type="default" htmlType="submit">
            <Auth.SocialIconWrapper>
            <FontAwesomeIcon icon={brands('google')} />
            </Auth.SocialIconWrapper>
            {`Ingresar con Google`}
          </Auth.SocialButton>
        </BaseForm.Item> */}
      </BaseForm>
    </Auth.FormWrapper>
  );
};
