import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { companiesStatistics, CompanyStatistics, subsystemWorkCStatistics, SubWorkCStatistics, workCStatistics } from "api/statistic.api";
export interface StatisticsSlice {
  companiesStat: CompanyStatistics[];
  companiesStatStatus: 'empty' | 'fulfilled' | 'error' | 'updating' ;
  subsWorkCStats: SubWorkCStatistics[]
  subsWorkCStatsStatus: 'empty' | 'fulfilled' | 'error' | 'updating' ;
  workCStats: SubWorkCStatistics[]
  workCStatsStatus: 'empty' | 'fulfilled' | 'error' | 'updating' ;
}

const initialState: StatisticsSlice = {
  companiesStat:[],
  companiesStatStatus: 'empty',
  subsWorkCStats:[],
  subsWorkCStatsStatus: 'empty',
  workCStats:[],
  workCStatsStatus: 'empty',
};


export const doListCompStatistics = createAsyncThunk("agreements/compStatistics", async () =>
  companiesStatistics().then((res) => res)
);

export const doListSubWorkCStatistics = createAsyncThunk("agreements/subWorkCStatistics", async () =>
  subsystemWorkCStatistics().then((res) => res)
);

export const doListWorkCStatistics = createAsyncThunk("agreements/workCStatistics", async () =>
  workCStatistics().then((res) => res)
);

export const doClear = createAction('statistics/clear', () => {
  return {
    payload: "clear",
  };
});

const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(doListCompStatistics.fulfilled, (state, action) => {
      state.companiesStat = action.payload;
      state.companiesStatStatus = 'fulfilled';
    });
    builder.addCase(doListSubWorkCStatistics.fulfilled, (state, action) => {
      state.subsWorkCStats = action.payload;
      state.subsWorkCStatsStatus = 'fulfilled';
    });
    builder.addCase(doListWorkCStatistics.fulfilled, (state, action) => {
      state.workCStats = action.payload;
      state.workCStatsStatus = 'fulfilled';
    });
    builder.addCase(doClear, (state, action) => {
      state.subsWorkCStats = [];
      state.subsWorkCStatsStatus = "empty";
      state.companiesStat = [];
      state.companiesStatStatus = "empty";
      state.workCStats = [];
      state.workCStatsStatus = "empty";
    });
  },
});

export default statisticsSlice.reducer;
