import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Company, Representative } from "./types";
import { useEffect, useState } from "react";
import { doUpdateStatus } from "store/slices/representativeSlice";
import { notificationController } from "controllers/notificationController";
import { Button, Popover, Tag, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getStatusLights } from "./utils";
import { CommentsModal } from "./CommentsModal";
import * as Sentry from "@sentry/react";

type OptionProps = {
  representative: Representative;
  idCompany: Company;
};
export const StatusUpdateRep = ({ representative, idCompany }: OptionProps) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [currentStatus, setStatus] = useState("");
  const [active, setActive] = useState(false);

  const toggle = () => {
    setActive(!active);
  };
  useEffect(() => {
    setStatus(representative.status);
  }, [representative.status]);

  const sendReqChangeStatus = (status: string) => {
    setLoading(true);
    dispatch(
      doUpdateStatus({
        id: representative._id?.toString() ?? "",
        status,
      })
    )
      .unwrap()
      .then(() => {
        notificationController.success({
          message: "Representante Actualizada",
        });
        setStatus(status);
      })
      .catch((error) => {
        notificationController.error({
          message: "Error ",
        });
        Sentry.captureMessage("Not able to delete representative");
      });
    setLoading(false);
  };

  return (
    <>
      {user?.role && user.role >= 1 ? (
        <Popover
          style={{
            backgroundColor: "grey",
            WebkitBoxShadow: " 0px 1px 18px 0px rgba(0,0,0,0.75)",
            boxShadow: "0px 1px 18px 0px rgba(0,0,0,0.75)",
            MozBoxShadow: " 0px 1px 18px 0px rgba(0,0,0,0.75)",
          }}
          placement="left"
          content={[
            <Button
              type="primary"
              style={{ backgroundColor: "#3fb618", marginInline: 20 }}
              size="small"
              loading={loading}
              onClick={() => {
                sendReqChangeStatus("Verificada");
              }}
            >
              Verificar
            </Button>,
            <Button
              type="primary"
              loading={loading}
              danger
              size="small"
              onClick={() => {
                toggle();
              }}
            >
              Rechazar
            </Button>,
          ]}
          trigger="click"
        >
          <Button
            type="primary"
            size="small"
            style={{ backgroundColor: getStatusLights(currentStatus) }}
            icon={
              <Tooltip title="Click Para cambiar estado">
                <FontAwesomeIcon
                  icon={solid("info-circle")}
                  style={{ color: "rgba(0,0,0,.45)", marginRight: 5 }}
                />
              </Tooltip>
            }
          >
            {currentStatus}
          </Button>
        </Popover>
      ) : (
        <Tag color={getStatusLights(currentStatus)}>{currentStatus}</Tag>
      )}
      <CommentsModal
        active={active}
        toggle={toggle}
        rep={representative}
        selectedRow={idCompany}
        statusCompany={idCompany.status}
      ></CommentsModal>
    </>
  );
};
