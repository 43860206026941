import axios from "axios";
import { AxiosError } from "axios";
import { ApiError } from "api/ApiError";
import { readToken } from "services/localStorage.service";

export const httpApi = axios.create({
  baseURL: "/api/",
});

httpApi.interceptors.request.use((config) => {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${readToken()}`,
  };

  return config;
});
httpApi.interceptors.response.use(undefined, (error: AxiosError | Error) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401) {
      let alink = document.createElement("a");
      alink.href = "/auth/logout";
      alink.click();
    } else {
      throw new ApiError<ApiErrorData>(
        error.message,
        error.response?.data ?? [],
      );
    }
  }
  throw new ApiError<ApiErrorData>(error.message);
});

export interface ApiErrorData {
  message: string;
}
