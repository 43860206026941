import { Avatar, Button, List, Space, message, Popconfirm, Spin, Modal, Divider, Alert } from "antd";
import { useState } from "react";
import { Company, Representative, typeRepresentative, typeRelation } from "./types";
import { Document } from "components/common/Document/Document";
import { StatusUpdateRep } from './StatusUpdateRep';
import { RepsEdit } from "./RepsEdit";
import { useAppDispatch } from "hooks/reduxHooks";
import { doRemove } from "store/slices/representativeSlice";
import { Agreement } from "components/Agreements/types";
import { doGetByRep as doListAgreementsByRep } from "store/slices/agreementSlice";
import { AgreementsList } from "components/dashboard/AgreementsList";
import * as Sentry from "@sentry/react"

type Props = {
  reps: Representative[];
  showAddRep: boolean;
  idCompany: Company;
  editMode?: boolean;
  type?: typeof typeRepresentative[number];
};

export const RepsList = ({ reps, showAddRep = false ,idCompany, editMode = false, type="contacto" }: Props) => {
  const dispatch = useAppDispatch();
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState("");
  const [ repSelected, setRepSelected ] = useState<Representative | null>();
  const [ agreementsWithRep, setAgreementsWithRep] = useState<Agreement[] | undefined>(undefined);
  const [ loadingDelete, setLoadingDelete ] = useState<boolean>(false);
  const [showNoDeleteModal, setShowNoDeleteModal] = useState<boolean>(false);

  const toggle = () => {
    setShowNoDeleteModal(!showNoDeleteModal);
  }

  const validateDeleteRep = async (repToDelete : string) => {
    let agreementsFiltered: Agreement[];
    let isOkToDeleteFlag: boolean = true;
    setLoadingDelete(true);
    agreementsFiltered = await doListAgreementsByRep(repToDelete).catch(
      (err) => {
        setShowNoDeleteModal(true);
        setLoadingDelete(false);
        isOkToDeleteFlag = false;
        return [];
      }
    );
    if (
      (agreementsFiltered && agreementsFiltered.length > 0) ||
      !isOkToDeleteFlag
    ) {
      setAgreementsWithRep(agreementsFiltered);
      setShowNoDeleteModal(true);
      setLoadingDelete(false);
      return false;
    }
    setLoadingDelete(false);
    return true;
  }

  const confirmDelete = async (rep: Representative) => {
    const canDelete = await validateDeleteRep(rep._id ?? "");
    if (!canDelete) {
      return false;
    }

    await dispatch(doRemove(rep))
      .unwrap()
      .then((repDeleted) => {
        message.success("Registro Eliminado");
      })
      .catch((error) => {
        if (error.message && error.message.includes("status code 403")) {
          setShowNoDeleteModal(true);
        } else {
          message.error("Error al eliminar representante");
          Sentry.captureMessage("Not able to delete representative");
          console.log("sentry error")
        }
      });
  };

  return (
    <>
      <List
        itemLayout='horizontal'
        dataSource={reps}
        loading={showAddRep}
        renderItem={(item) => (
          <List.Item key={item._id} extra={
            <>
              <Space>
                <StatusUpdateRep idCompany={idCompany} representative={item}/>
                { editMode && (
                  <Button
                    type='primary'
                    size='small'
                    onClick={()=>{setRepSelected(item)}}
                    >
                      Editar
                  </Button>
                )}
                { editMode && (
                  <Spin spinning={loadingDelete}>
                    <Popconfirm
                      title={`Eiminar al ${typeRelation[type]}`}
                      description="¿Está seguro de eliminar este registro?"
                      onConfirm={()=>confirmDelete(item)}
                      okText="Si"
                      cancelText="No"
                    >
                      <Button
                        type='primary'
                        danger
                        size='small'
                        >
                          Eliminar
                      </Button>
                    </Popconfirm>
                  </Spin>
                )}
              </Space>
            </>
            }>
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{
                    backgroundColor: `gray`,
                    verticalAlign: "middle",
                  }}
                  size='large'
                >
                  {item.name.charAt(0).toUpperCase()}
                </Avatar>
              }
              title={`${item.name}` }
              description={(
                <div>
              <p>{`${item.position} - ${item.email} - ${item.phone}`}</p>
              <Space>
                { item.idFileId ? 
                  (<Button
                    type='link'
                    onClick={() => {
                      setPdfViewerId(item.idFileId || '');
                      setShowDocument(true);
                    }}
                  >
                    Identificación Oficial
                  </Button>
                  ): 'Sin Identificación Oficial'
                }
                { item.powerFileId ? 
                  (<Button
                    type='link'
                    onClick={() => {
                      setPdfViewerId(item.powerFileId || '');
                      setShowDocument(true);
                    }}
                  >
                    Poder Notarial
                  </Button>
                  ): 'Sin Poder Notarial'
                }
              </Space>
            </div>
              )}
            />
          </List.Item>
        )}
      />
      { repSelected && (
        <RepsEdit
          rep={repSelected}
          open={!!repSelected}
          onClose={()=>{setRepSelected(null)}}
          type={type}/>
      )}
      <Document
        fileId={pdfViewerId}
        open={showDocument}
        onCancel={() => setShowDocument(false)}
        onOk={() => setShowDocument(false)}
      />
        <Modal
        title="No se puede eliminar al representante"
        open={showNoDeleteModal}
        onOk={() => toggle()}
        onCancel={() => toggle()}
        destroyOnClose
      >
        <p>
          No se puede eliminar un repesentante que tiene expedientes asignados. Por
          favor cambia los expedientes antes de eliminar al representante
        </p>
        <div>
          <Divider orientation="left">
            Estudiantes con expedientes con el representante
          </Divider>
          {!agreementsWithRep || agreementsWithRep?.length <= 0 ? (
            <Alert
              message="Advertencia"
              description="Este representante no se puede eliminar debido a que tiene expedientes con estudiantes de otro plantel o subsitema"
              type="warning"
              showIcon
            />
          ) : (
            <AgreementsList agreements={agreementsWithRep} ></AgreementsList>
          )}
        </div>
      </Modal>
    </>
  );
};
