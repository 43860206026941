import { hoursMinutes } from "components/students/types";

export const valueDate = (date: any, hour: boolean = false) => {
  // Convert json ISODate to Date
  const data = new Date(date);
  const timeValid = data.getTime();
  if (!timeValid) {
    return false;
  }
  if (!hour) {
    return data.toLocaleDateString("es-MX", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  }
  return data.toLocaleDateString("es-MX", {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

export function getEdad(dateString: any) {
  let hoy = new Date();
  let fechaNacimiento = new Date(dateString);
  let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
  let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
  if (
    diferenciaMeses < 0 ||
    (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
  ) {
    edad--;
  }
  return edad;
}
export const cleanStrings = (text: string) => {
  let result = text
    .normalize("NFD")
    .replaceAll(/([aeio])\u0301|(u)[\u0301\u0308]/gi, "$1$2")
    .normalize();
  result = result.replaceAll(" ", "_");
  return result;
};

export const getWeekDiff = (startDate: any, inactiveDate: any) => {
  const dataStart = new Date(startDate);
  const dataEnd = new Date(inactiveDate);

  const dayOfWeekStart = dataStart.getDay();
  if(dayOfWeekStart !== 1){
    const daysUntilNextMonday = (dayOfWeekStart === 0 ? 1 : 8) - dayOfWeekStart;
    dataStart.setDate(dataStart.getDate() + daysUntilNextMonday);
    dataStart.setHours(0, 0, 0);
  }
  const dayOfWeekEnd = dataEnd.getDay();
  const daysFromPrevSunday = 0 + dayOfWeekEnd;
  dataEnd.setDate(dataEnd.getDate() - daysFromPrevSunday);
  dataEnd.setHours(0, 0, 0)
  const weeksDiff = Math.floor((dataEnd.getTime() - dataStart.getTime()) / (7 * 24 * 60 * 60 * 1000)) + 1;

  return weeksDiff;
};
export const getHoursMinutesString = (timeObj: hoursMinutes | false) => {
  if (timeObj === false) {
    return " Hrs "
  }
  if(timeObj.minutes === 0) {
    return `${timeObj.hours < 10 ? "0" : ""}${timeObj.hours}`
  }
  return `${timeObj.hours < 10 ? "0" : ""}${timeObj.hours}:${timeObj.minutes < 10 ? "0" : ""}${timeObj.minutes}`
 }
