import { httpApi } from "api/http.api";
import { File } from "components/files/types";

export type Presign = {
  url: string;
  fileId: string;
  storageType: string;
}

const controller = `file`;

export const upload = (filePayload: FormData): Promise<File[]> => {
  return httpApi
    .post<File[]>(`${controller}`, filePayload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(({ data }) => data);
};

export const getFileUrlbyId = (id: string): Promise<string> =>
  httpApi.get<string>(`${controller}/geturl/${id}`).then(({ data }) => data);

  export const getFileBufferbyId = (id: string): Promise<Uint8Array> =>
  httpApi.get(`${controller}/getfile/${id}`, { responseType: 'arraybuffer' })
    .then(({ data }) => new Uint8Array(data));

export const getPresignedUrl = (): Promise<Presign> =>
  httpApi.get<Presign>(`${controller}/presignurl`).then(({data}) => data );
