import React, { useState, useEffect } from "react";
import * as S from "./Employees.styles";
import { useNavigate } from "react-router-dom";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Switch, Input, Select, Row, Col, Divider, Form } from 'antd';
import { doRegister } from "store/slices/employeeSlice";
import { notificationController } from "controllers/notificationController";
import { Employee, PermissionsType, permissionsDefault, PermissionModulesRelation as moduleAccess, AccessLvls } from "./types"
import { WorkCenter } from "../workcenters/types";
import { doList as WorkcenterSub} from 'store/slices/workCenterSlice';
import { doList as SubsystemList } from "store/slices/subsystemSlice";
import { OptionsSelect } from "types/optionsSelect";
import { EMPLOYEES_PATH } from '../router/AppRouter';
import { CloseButton } from "components/common/buttons/Button/CloseButton";
import { doGetApprovalProcess } from 'store/slices/configSlice';
import { escapeRegExp } from "lodash";

export const EmployeeNew: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  //initial data
    //config for approval roles
    const {validApprovalRoles, configStatus }  = useAppSelector((state) => state.config);
    const [optionApprovalRoles, setOptionApprovalRoles] = useState<OptionsSelect[]>([]);
    //for access permissions
    const [userPermissions, setUserPermissions] = useState<PermissionsType[]>(permissionsDefault);
    const accessLevelsOptions: OptionsSelect[] = [
      {
        label: "Sin acceso",
        value: AccessLvls.noAccess,
        key: "0"
      },
      {
        label: "Solo lectura",
        value: AccessLvls.readOnly,
        key: "1"
      },
      {
        label: "Lecura y escritura",
        value: AccessLvls.readWrite,
        key: "2"
      }
    ];
    // WorkCenter
    const { workcenters,  status: workcenterStateStatus } = useAppSelector((state) => state.workcenter);
    const { subsystems,  status: subsystemStateStatus } = useAppSelector((state) => state.susbsystem);
    const [valueWorkCenter, setValueWorkCenter] = useState<WorkCenter[]>([]);
    const [optionWorkCenter, setOptionWorkCenter] = useState<OptionsSelect[]>([]);
    const [optionsSubsystem, setOptionsSubsystem] = useState<OptionsSelect[]>([]);
    const [valueSubSystem, setValueSubSystem] = useState();

    // approval roles
    useEffect(() => {
      if (configStatus !== "fulfilled") {
        dispatch(doGetApprovalProcess());
      } else {
        const options = validApprovalRoles.map((role) => ({
          label: role.name!,
          value: role.name!,
          key: role.name!,
        }));
        setOptionApprovalRoles(options);
      }
    }, [dispatch, validApprovalRoles, configStatus]);

   // Select Functions
   useEffect(() => {
    if(workcenterStateStatus !== 'fulfilled'){
      dispatch(WorkcenterSub())
    }
  }, [dispatch, workcenterStateStatus]);

  useEffect(() => {
    if (subsystemStateStatus !== "fulfilled") {
      dispatch(SubsystemList());
    }
    const optionsRaw: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.nameShort ?? " - "} `,
      value: subs.nameShort!,
      key: subs._id!,
    }));
    setOptionsSubsystem(optionsRaw);
  }, [dispatch, subsystemStateStatus, subsystems]);

  //workcenters & subsystem
  useEffect(() => {
    const optionsRaw: OptionsSelect[] = workcenters.map((workCenter) => ({
      label: workCenter.name!,
      value: workCenter._id!,
      key: workCenter._id!,
    }));
    setOptionWorkCenter(optionsRaw);
  }, [workcenters]);

  const handlePermissionChange = (newValue: number, arrayPos: number) => {
    const updatedUserPermissions = [...userPermissions];
    updatedUserPermissions[arrayPos].permission = newValue;
    setUserPermissions(updatedUserPermissions);
  }

  const handleUserTypeChange = (newValue: number) => {
    let newUserPermissions = [];
    if (newValue < 1) {
      newUserPermissions = [
        {module: "user", permission: 1},
        {module: "agreement", permission: 2},
        {module: "student", permission: 2},
        {module: "company", permission: 2},
        {module: "workcenter", permission: 1},
        {module: "subsystem", permission: 0},
        {module: "export", permission: 1},
      ];
    } else{
      newUserPermissions = userPermissions.map((p)=>({...p, permission: AccessLvls.readWrite}));
    }
    setUserPermissions(newUserPermissions);
    form.setFieldValue("permission.user",newUserPermissions[moduleAccess.user].permission);
    form.setFieldValue("permission.agreement",newUserPermissions[moduleAccess.agreement].permission);
    form.setFieldValue("permission.student",newUserPermissions[moduleAccess.student].permission);
    form.setFieldValue("permission.company",newUserPermissions[moduleAccess.company].permission);
    form.setFieldValue("permission.workcenter",newUserPermissions[moduleAccess.workcenter].permission);
    form.setFieldValue("permission.subsystem",newUserPermissions[moduleAccess.subsystem].permission);
    form.setFieldValue("permission.export",newUserPermissions[moduleAccess.export].permission);
  }

  const changeSubsystem = (subsystemSelected: any) => {
    const newOptions= workcenters.filter(value => value.subsystemShort === subsystemSelected ).map((workCenter) => ({
      label: workCenter.name!,
      value: workCenter._id!,
      key: workCenter._id!,
    }));
    setOptionWorkCenter(newOptions);
    setValueSubSystem(subsystemSelected);
  };

  const filterWorkcenter = (workCenterName: string) => {
    let escapedWorkcName = escapeRegExp(workCenterName);
    let inputString= new RegExp(`${escapedWorkcName}`, 'gi');
    const newOptions = workcenters.filter(value => inputString.test(value.name)).map((workCenter) => ({
      label: workCenter.name!,
      value: workCenter._id!,
      key: workCenter._id!,
    }));;
    setOptionWorkCenter(newOptions);
  }

  const handleSubmit = (values: Employee) => {
    setLoading(true);
    values.permissions = userPermissions;
    dispatch(doRegister(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: "Colaborador registrado correctamente",
        });
        navigate("/colaboradores")
      })
      .catch((err) => {
        notificationController.error({
          message: "Error al registrar colaborador",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <S.Wrapper>
        <S.Card
          id="employees-new"
          title={
            <>
              <CloseButton
                onClick={() => {
                  navigate(EMPLOYEES_PATH);
                }}
              ></CloseButton>
              <span>&nbsp; {`Nuevo Colaborador`}</span>
            </>
          }
          padding="1.25rem"
        >
          <BaseForm
            layout="vertical"
            onFinish={handleSubmit}
            requiredMark="optional"
            form={form}
          >
            <BaseForm.Item
              name="name"
              label={`Nombre(s)`}
              rules={[{ required: true, message: "Este campo es obligatorio" }]}
            >
              <Input placeholder="Ingrese el nombre" />
            </BaseForm.Item>
            <BaseForm.Item
              name="email"
              label={`Correo electrónico`}
              rules={[
                { required: true, message: "Este campo es obligatorio" },
                {
                  type: "email",
                  message: "Por favor, ingresa un correo electrónico válido",
                },
              ]}
            >
              <Input placeholder="Ingrese la dirección de correo electronico" />
            </BaseForm.Item>
            <BaseForm.Item name="phone" label={`Teléfono`}>
              <Input placeholder="Ingrese los 10 digitos sin espacios ni diagonales" />
            </BaseForm.Item>
            <BaseForm.Item name="position" label={`Puesto`}>
              <Input placeholder="Ingrese el puesto del colaborador" />
            </BaseForm.Item>
            <Row>
              <Col style={{ marginRight: 25 }}>
                <BaseForm.Item label={`Subsistema`} name="subsystem">
                  <Select
                    style={{ width: 200 }}
                    value={valueSubSystem}
                    onChange={changeSubsystem}
                    placeholder="Sub-Sistema"
                    options={optionsSubsystem}
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={1}>
                <BaseForm.Item
                  name="workcenter"
                  label={`Plantel`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    showSearch
                    value={valueWorkCenter}
                    onSearch={filterWorkcenter}
                    onChange={(newValue) => {
                      setValueWorkCenter(newValue as WorkCenter[]);
                    }}
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ width: "100%" }}
                    options={optionWorkCenter}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col>
                <BaseForm.Item name="status" label="Activo">
                  <Switch />
                </BaseForm.Item>
              </Col>
              <Col>
                <BaseForm.Item
                  name="role"
                  label={`Tipo de usuario`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    defaultValue={0}
                    onChange={handleUserTypeChange}
                    options={[
                      {
                        label: "Usuario",
                        value: 0,
                        key: 0,
                      },
                      {
                        label: "Administrador",
                        value: 1,
                        key: 1,
                      },
                    ]}
                  />
                </BaseForm.Item>
              </Col>
              <Col>
                <BaseForm.Item
                  name="approvementRole"
                  label={`Rol de aprobación para expedientes`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    options={optionApprovalRoles}
                  />
                </BaseForm.Item>
              </Col>
              <BaseForm.Item
              name="permission.export"
              label={`Permiso para exportar expedientes`}
              rules={[
                { required: false, message: "Este campo es obligatorio" },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                options={[
                  {label: "No puede exportar", value: AccessLvls.readOnly},
                  {label: "Puede exportar", value: AccessLvls.readWrite},
                ]}
                defaultValue={AccessLvls.readOnly}
                onChange={(val) => handlePermissionChange(val,moduleAccess.export)}
              />
            </BaseForm.Item>
            </Row>
            <Divider orientation="left">Permisos de acceso</Divider>
            <Row gutter={[20,20]}>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.user"
                  label={`Acceso a colaboradores`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    defaultValue={AccessLvls.readOnly}
                    value={userPermissions[moduleAccess.user].permission}
                    style={{ width: "100%" }}
                    options={accessLevelsOptions}
                    onChange={(val) => handlePermissionChange(val,moduleAccess.user)}
                  />
                </BaseForm.Item>
              </Col>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.agreement"
                  label={`Acceso a expedientes`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                   defaultValue={AccessLvls.readWrite}
                   value={userPermissions[moduleAccess.agreement].permission}
                   style={{ width: "100%" }}
                   options={accessLevelsOptions}
                   onChange={(val) => handlePermissionChange(val,moduleAccess.agreement)}
                  />
                </BaseForm.Item>
              </Col>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.subsystem"
                  label={`Acceso a subsistemas`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                   defaultValue={AccessLvls.noAccess}
                   value={userPermissions[moduleAccess.subsystem].permission}
                   style={{ width: "100%" }}
                   options={accessLevelsOptions}
                   onChange={(val) => handlePermissionChange(val,moduleAccess.subsystem)}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row gutter={[20,20]}>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.student"
                  label={`Acceso a alumnos`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    defaultValue={AccessLvls.readWrite}
                    value={userPermissions[moduleAccess.student].permission}
                    style={{ width: "100%" }}
                    options={accessLevelsOptions}
                    onChange={(val) => handlePermissionChange(val,moduleAccess.student)}
                  />
                </BaseForm.Item>
              </Col>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.company"
                  label={`Acceso a empresas`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                   defaultValue={AccessLvls.readWrite}
                   value={userPermissions[moduleAccess.company].permission}
                   style={{ width: "100%" }}
                   options={accessLevelsOptions}
                   onChange={(val) => handlePermissionChange(val,moduleAccess.company)}
                  />
                </BaseForm.Item>
              </Col>
              <Col span={5}>
                <BaseForm.Item
                  name="permission.workcenter"
                  label={`Acceso a planteles`}
                  rules={[
                    { required: false, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    defaultValue={AccessLvls.readOnly}
                    value={userPermissions[moduleAccess.workcenter].permission}
                    style={{ width: "100%" }}
                    options={accessLevelsOptions}
                    onChange={(val) => handlePermissionChange(val,moduleAccess.workcenter)}
                  />
                </BaseForm.Item>
              </Col>
            </Row>

            <BaseForm.Item noStyle>
              <S.SubmitButton
                type="primary"
                htmlType="submit"
                size="large"
                loading={isLoading}
              >
                {`Registrar`}
              </S.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </S.Card>
      </S.Wrapper>
    </>
  );
};
