import { Descriptions, Divider, Drawer } from "antd";
import { WorkCenter, initialValues } from "./types";
import { OptionsDrawerBar } from "./OptionsDrawer";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions"

const {writeWorkcenters} = PermissionsToAccess


type WorkcenterDetailProps = {
  selectedRow: WorkCenter | null;
  onClose: () => void;
  open: boolean;
};

export const WorkcenterDetail = ({
  selectedRow,
  open,
  onClose,
}: WorkcenterDetailProps) => {
  return (
    <Drawer
      title={`Detalles del plantel`}
      placement="right"
      open={open}
      destroyOnClose
      onClose={onClose}
      width={`50%`}
      extra={
        CheckAccess(writeWorkcenters) && [
          <OptionsDrawerBar rowSelected={selectedRow ?? initialValues} />,
        ]
      }
    >
      {selectedRow && (
        <>
          <Divider>Plantel</Divider>
          <Descriptions bordered size="small">
            <Descriptions.Item key="name" label="Nombre completo" span={3}>
              {selectedRow.name}
            </Descriptions.Item>
            <Descriptions.Item
              key="principal"
              label="Director(a) del plantel "
              span={3}
            >
              {selectedRow.principal ? selectedRow.principal.name : ""}
            </Descriptions.Item>
            <Descriptions.Item
              key="keySep"
              label="CCT"
              span={3}
            >
              {selectedRow.keySep ?? ""}
            </Descriptions.Item>
          </Descriptions>

          <Divider>Subsistema</Divider>
          <Descriptions bordered size="small">
            <Descriptions.Item
              key="name"
              label="Nombre del subsistema"
              span={3}
            >
              {selectedRow.subsystem}
            </Descriptions.Item>
            <Descriptions.Item
              key="nameShort"
              label="Nombre corto"
              span={3}
            >
              {selectedRow.subsystemShort}
            </Descriptions.Item>
            <Descriptions.Item
              key="principal"
              label="Director(a) general del subsistema "
              span={3}
            >
              {selectedRow.currentSubsystemPrincipal ?? ""}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
    </Drawer>
  );
};
