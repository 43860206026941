import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WorkCenter, initialValues } from "./types";
import { doList as SubsystemList } from "store/slices/subsystemSlice";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Input, Select, Row, Col, Form } from "antd";
import { doRegister } from "store/slices/workCenterSlice";
import { notificationController } from "controllers/notificationController";
import * as S from "./WorkCenter.styles";
import { WORKCENTERS_PATH } from "components/router/AppRouter";
import { doList as pricipalDoList } from "store/slices/employeeSlice";
import { OptionsSelect } from "types/optionsSelect";
import { CloseButton } from "components/common/buttons/Button/CloseButton";

export const WorkCenterNewForm: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { employees, status: employeesStateStatus } = useAppSelector((state) => state.employee);
  const { subsystems, status: subsystemStateStatus } = useAppSelector((state) => state.susbsystem)

  const [isLoading, setLoading] = useState(false);
  //Select
  const [optionsEmployee, setOptionsEmployee] = useState<OptionsSelect[]>([]);
  const [optionsSubsystem, setOptionsSubsystem] = useState<OptionsSelect[]>([]);
  const [optionsSubsystemLong, setOptionsSubsystemLong] = useState<OptionsSelect[]>([]);
  const [optionsSubsystemPrincipals, setOptionsSubsystemPrincipals] = useState<OptionsSelect[]>([]);

  useEffect(() => {
    if (employeesStateStatus !== "fulfilled") {
      dispatch(pricipalDoList());
    }
    const principals = employees.map((e) => ({
      label: e.name,
      value: e._id!,
      key: e._id!,
    }));
    setOptionsEmployee(principals);
  }, [employees, dispatch, employeesStateStatus]);

  useEffect(() => {
    if (subsystemStateStatus !== "fulfilled") {
      dispatch(SubsystemList());
    }
    const optionsRaw: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.nameShort ?? " - "} `,
      value: subs.nameShort!,
      key: subs._id!,
    }));
    setOptionsSubsystem(optionsRaw);
    const optionsRawLong: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.name ?? " - "} `,
      value: subs.name!,
      key: subs._id!,
    }));
    setOptionsSubsystemLong(optionsRawLong);
    const optionsRawPrincipals: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.principal ?? " - "} `,
      value: subs.principal!,
      key: subs._id!,
    }));
    setOptionsSubsystemPrincipals(optionsRawPrincipals);
  }, [dispatch, subsystemStateStatus, subsystems]);

  const handleSubsystemChange = (susbSelected : string) => {
    const subsystemSel = subsystems.find((s) => s.nameShort === susbSelected);
    form.setFieldValue("subsystem",subsystemSel?.name)
    form.setFieldValue("currentSubsystemPrincipal", subsystemSel?.principal)
  }
  const handleSubsystemLongChange = (susbSelected : string) => {
    const subsystemSel = subsystems.find((s) => s.name === susbSelected);
    form.setFieldValue("subsystemShort",subsystemSel?.nameShort)
    form.setFieldValue("currentSubsystemPrincipal", subsystemSel?.principal)
  }

  const handleSubmit = (values: WorkCenter) => {
    setLoading(true);
    dispatch(doRegister(values))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: "Plantel registrado correctamente",
        });
        navigate(WORKCENTERS_PATH)
      })
      .catch((err) => {
        notificationController.error({ message: "Error al registrar plantel" });
        setLoading(false);
      });
  };

  return (
    <>
      <S.Wrapper>
        <S.SmallCard
        id='employees-new'
        title={
          <>
            <CloseButton onClick={()=>{navigate(WORKCENTERS_PATH)}}></CloseButton>
            <span>&nbsp; {`Nuevo Plantel`}</span>
          </>
        }
        padding='3rem'>
          <BaseForm
            layout='vertical'
            onFinish={handleSubmit}
            requiredMark='optional'
            initialValues={initialValues}
            form={form}
          >
            <Row>
              <Col style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name='nameShort'
                  label={`Nombre Corto`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Input
                    placeholder='CBTIS 258'
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={2}>
                <BaseForm.Item
                  name='name'
                  label={`Nombre Completo`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Input
                    placeholder='CENTRO DE BACHILLERATO TECNOLÓGICO INDUSTRIAL Y DE SERVICIOS NÚM. 258'
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
            <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name="principal"
                  label={`Director(a) del Plantel`}
                  required
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Director(a) del plantel"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={optionsEmployee}
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1} style={{ marginRight: 20 }}>
                <BaseForm.Item
                  name='subsystemShort'
                  label={`Subsistema`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    options={optionsSubsystem}
                    onChange={handleSubsystemChange}
                    placeholder='Selecciona un subsistema'
                  />
                </BaseForm.Item>
              </Col>
              <Col flex={2}>
                <BaseForm.Item
                  name='subsystem'
                  label={`Nombre Completo del Subsistema`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Select
                    style={{ width: "100%" }}
                    options={optionsSubsystemLong}
                    onChange={handleSubsystemLongChange}
                    placeholder='Selecciona un subsistema'
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
            <Col flex={1}>
                <BaseForm.Item
                  label={`Director(a) General Actual del subsistema`}
                  requiredMark={false}
                  name="currentSubsystemPrincipal"
                >
                    <Select
                    style={{ width: "100%" }}
                    options={optionsSubsystemPrincipals}
                    placeholder='Selecciona un subsistema'
                  />
                </BaseForm.Item>
              </Col>
            </Row>
            <Row>
              <Col flex={1} style={{ marginRight: 20 }}>
              <BaseForm.Item
                  name="keySep"
                  label={`Clave del Plantel`}
                  rules={[
                    { required: true, message: "Este campo es obligatorio" },
                  ]}
                >
                  <Input placeholder="Ingrese la clave" />
                </BaseForm.Item>
              </Col>
            </Row>

            <BaseForm.Item noStyle>
              <S.SubmitButton
                type='primary'
                htmlType='submit'
                size='large'
                loading={isLoading}
              >
                {`Registrar`}
              </S.SubmitButton>
            </BaseForm.Item>
          </BaseForm>
        </S.SmallCard>
      </S.Wrapper>
    </>
  );
};
