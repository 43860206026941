import React, { useState } from "react";
import * as S from "./subsystem.styles";
import { Subsystem } from "./types";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch } from "hooks/reduxHooks";
import { Col, Form, Input, Row } from "antd";
import { doUpdate } from "store/slices/subsystemSlice";
import { notificationController } from "controllers/notificationController";
import { SUBSYSTEMS_PATH } from "components/router/AppRouter";
import { useNavigate } from "react-router-dom";

interface SubsystemEditProps {
  subsystem: Subsystem;
}

export const SubsystemEdit = ({ subsystem }: SubsystemEditProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const handleSubmit = (values: Subsystem) => {
    setLoading(true);
    dispatch(doUpdate(values))
      .unwrap()
      .then((subsystemSaved) => {
        notificationController.success({
          message: "Se editó el subsistema correctamente",
        });
        navigate(SUBSYSTEMS_PATH);
      })
      .catch((err) => {
        notificationController.error({
          message: "Error al editar el subsistema",
        });
        setLoading(false);
      });
  };

  return (
    <BaseForm
      form={form}
      layout="vertical"
      onFinish={handleSubmit}
      requiredMark="optional"
      initialValues={subsystem}
    >
      <Row>
        <Col style={{ marginRight: 20 }}>
          <BaseForm.Item name="_id" hidden />
          <BaseForm.Item
            name="nameShort"
            label={`Nombre Corto`}
            rules={[{ required: true, message: "Este campo es obligatorio" }]}
          >
            <Input
              placeholder="CBTIS
"
            />
          </BaseForm.Item>
        </Col>
        <Col flex={2}>
          <BaseForm.Item
            name="name"
            label={`Nombre Completo`}
            rules={[{ required: true, message: "Este campo es obligatorio" }]}
          >
            <Input placeholder="CENTRO DE BACHILLERATO TECNOLÓGICO INDUSTRIAL Y DE SERVICIOS" />
          </BaseForm.Item>
        </Col>
      </Row>
      <Row>
        <Col flex={1} style={{ marginRight: 20 }}>
          <BaseForm.Item
            name="principal"
            label={`Director(a) general del subsistema`}
            required
            rules={[{ required: true, message: "Este campo es obligatorio" }]}
          >
            <Input />
          </BaseForm.Item>
        </Col>
      </Row>
      <BaseForm.Item noStyle>
        <S.SubmitButton
          type="primary"
          htmlType="submit"
          size="large"
          loading={isLoading}
        >
          {`Actualizar`}
        </S.SubmitButton>
      </BaseForm.Item>
    </BaseForm>
  );
};
