import { Button, Popover, Table } from "antd";
import React, { useEffect, useState } from "react";
import * as S from "./WorkCenter.styles";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import { doList, doRemove } from "store/slices/workCenterSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { WorkCenter } from "components/workcenters/types";
import { WORKCENTERS_PATH } from "components/router/AppRouter";
import { filterProps } from "components/common/FilterProps";
import { ColumnsType } from "antd/es/table/interface";
import { Employee } from "components/employees/types";
import { WorkcenterDetail } from "./WorkcenterDetail";
import { CheckAccess } from "checkAccess/CheckAccess";
import { PermissionsToAccess } from "checkAccess/ConstPermissions"

const {writeWorkcenters} = PermissionsToAccess


export const WorkCenterListTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { workcenters, status: workcenterStateStatus } = useAppSelector((state) => state.workcenter);
  const [workcenterColumns, setWorkcenterColumns] = useState<WorkCenter[]>();
  const [ selectedRowData, setSelectedRowData ] = useState<WorkCenter | null>(null)
  const [ showDetailDrawer, setShowDetailDrawer ] = useState(false);

  useEffect(() => {
    if(workcenterStateStatus !== 'fulfilled'){
      dispatch(doList())
    }
  }, [dispatch, workcenterStateStatus]);
  useEffect(() => {
    setWorkcenterColumns(workcenters);
  }, [workcenters]);
  const onDeleteWorkSpace = async (data: any) => {
    const dataFiltered = workcenters.filter(
      (workspace) => workspace._id === data._id
    );
    await doRemove(dataFiltered[0]);
    setWorkcenterColumns((pre) => {
      return pre!.filter((workcenter) => workcenter._id !== data._id);
    });
    return true;
  };
  const columns: ColumnsType<WorkCenter> = [
    {
      title: "Subsistema",
      dataIndex: "subsystemShort",
      key: "subsystemShort",
      ...filterProps,
      onFilter: (value, record) => {
        return record.subsystemShort && record.subsystemShort
          ? record.subsystemShort.toLowerCase()
            .includes(value.toString().toLowerCase()) || false
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) =>
      a.subsystemShort && b.subsystemShort
      ? a.subsystemShort.localeCompare(b.subsystemShort)
      : a.subsystemShort
      ? a.subsystemShort.localeCompare("")
      : b.subsystemShort
      ? b.subsystemShort.localeCompare("")
      : "".localeCompare(""),
    },
    {
      title: "Plantel",
      dataIndex: "nameShort",
      key: "index",
      sorter: (a, b) => a.name.localeCompare(b.nameShort),
      ...filterProps,
      onFilter: (value, record) => {
        return record.nameShort
          .toLowerCase()
          .includes(value.toString().toLowerCase());
      },
    },
    {
      title: "Director(a)",
      dataIndex: "principal",
      key: "principal",
      ...filterProps,
      render: (principal: Employee) => `${principal ? principal.name : ""}`,
      onFilter: (value, record) => {
        return record.principal && record.principal.name
          ? record.principal?.name.toLowerCase()
            .includes(value.toString().toLowerCase()) || false
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) =>
      a.principal && b.principal
      ? a.principal.name.localeCompare(b.principal.name)
      : a.principal
      ? a.principal.name.localeCompare("")
      : b.principal
      ? b.principal.name.localeCompare("")
      : "".localeCompare(""),
    },
    {
      title: "CCT",
      dataIndex: "keySep",
      key: "principal",
      ...filterProps,
      onFilter: (value, record) => {
        return record.keySep
          ? record.keySep.toLowerCase()
            .includes(value.toString().toLowerCase()) || false
          : "".toLowerCase().includes(value.toString().toLowerCase());
      },
      sorter: (a, b) =>
      a.keySep && b.keySep
      ? a.keySep.localeCompare(b.keySep)
      : a.keySep
      ? a.keySep.localeCompare("")
      : b.keySep
      ? b.keySep.localeCompare("")
      : "".localeCompare(""),
    },
    {
      title: "Acción",
      dataIndex: "",
      align: "center",
      key: "x",
      render: (data: WorkCenter) => {
        return (
          <div>
            <span
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Popover
              content={
                <Button
                  type='primary'
                  danger
                  block
                  onClick={() => {
                    onDeleteWorkSpace(data);
                  }}
                >
                  Confirmar
                </Button>
              }
              trigger='click'
              placement='left'
            >
              <FontAwesomeIcon icon={solid("trash")} style={{ color: "red" }} />
            </Popover>
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <S.Wrapper>
        <S.Card
          id="workcenters-list"
          title={`Planteles`}
          padding="1.25rem"
          extra={
            CheckAccess(writeWorkcenters) && (
              <Button
                type="primary"
                onClick={() => {
                  navigate(`${WORKCENTERS_PATH}/nuevo`);
                }}
              >
                Nuevo Plantel
              </Button>
            )
          }
        >
          <Table
            dataSource={workcenterColumns}
            columns={
              CheckAccess(writeWorkcenters)
                ? columns
                : columns.filter((col) => col.title !== "Acción")
            }
            rowKey="_id"
            tableLayout="fixed"
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  setSelectedRowData(record);
                  setShowDetailDrawer(true);
                },
              };
            }}
          />
        </S.Card>
        <WorkcenterDetail
          onClose={() => {
            setShowDetailDrawer(false);
          }}
          selectedRow={selectedRowData}
          open={showDetailDrawer}
        />
      </S.Wrapper>
    </>
  );
};
