/*eslint valid-typeof: off*/
import { useState, useEffect, useRef, useMemo } from "react";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";
import { useAppDispatch, useAppSelector } from "hooks/reduxHooks";
import { Select, Row, Col, Form, Divider, Input, Progress, Tooltip, Spin  } from "antd";
import { doList as doCompanyList } from "store/slices/companySlice";
import {search as searchStudents} from "api/student.api"
import { doList } from "store/slices/workCenterSlice";
import { notificationController } from "controllers/notificationController";
import * as S from "../Agreements.styles";
import { AGREEMENTS_PATH } from "components/router/AppRouter";
import { Representative } from "components/companies/types";
import { getById } from "api/agreements.api";
import { OptionsSelect } from "types/optionsSelect";
import { Agreement, initialValues as AgreementInit} from "components/Agreements/types";
import { initialValues } from "../../companies/types";
import { useNavigate } from "react-router-dom";
import { doPatch } from "store/slices/agreementSlice";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { Document } from "components/common/Document/Document";
import { doList as pricipalDoList } from "store/slices/employeeSlice";
import { doList as repsDoList } from "store/slices/representativeSlice";
import { doList as SubsystemList } from "store/slices/subsystemSlice";
import { CloseButton } from "components/common/buttons/Button/CloseButton";
import { IsAdultStudent, agreementFormSetter, handleTimeChange } from "../utils/utils";
import { Student } from "components/students/types";
import { debounce, escapeRegExp } from "lodash";
import { fileList, getColabFiles, getFilesLearningAgreement } from "../utils/fileList";
import { LaptopFields } from "../utils/LaptopFields";
import { InnactiveLetterField } from "./InactiveLetterField";
import { TimeFields } from "../utils/timeFields";
import { RotationFields } from "../utils/RotationFields";

type EditAgreementProps = { agreementId: { id: string } };
dayjs.extend(customParseFormat);
export const EditAgreement = ({ agreementId }: EditAgreementProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { employees, status } = useAppSelector((state) => state.employee);
  const [form] = Form.useForm();

  const [isLoading, setLoading] = useState(false);
  // Inital Data
  const { companies, status: companyStateStatus } = useAppSelector((state) => state.company);
  const { representatives, status: repsStateStatus } = useAppSelector((state) => state.representative);
  const { workcenters,  status: workcenterStateStatus } = useAppSelector((state) => state.workcenter);
  const { subsystems,  status: subsystemStateStatus } = useAppSelector((state) => state.susbsystem);
  const { user } = useAppSelector((state) => state.user)
  // WorkCenter
  const [optionWorkCenter, setOptionWorkCenter] = useState<OptionsSelect[]>([]);
  const [valueSubSystem, setValueSubSystem] = useState("");
  const [optionsSubsystem, setOptionsSubsystem] = useState<OptionsSelect[]>([]);

  const [agreement, setAgreement] = useState<Agreement | null>(null);
  // WorkCenter
  const [optionStudents, setOptionStudents] = useState<OptionsSelect[]>([]);
  // Company
  // Select for Compnay
  const [optionsCompany, setOptionsCompany] = useState<OptionsSelect[]>([]);
  const [ optionsEmployee, setOptionsEmployee ] = useState<OptionsSelect[]>([]);
  const [companyContacts, setCompanyContacts] = useState<Representative[]>([]);
  const [optionsCompanyContacts, setOptionsCompanyContacts] = useState<
    OptionsSelect[]
  >([]);
  const [companyLegalReps, setCompanyLegalReps] = useState<Representative[]>([]);
  const [optionsCompanyLegalReps, setOptionsCompanyLegalReps] = useState<
    OptionsSelect[]
  >([]);

  // fetch students search
  const [filteredStudents, setFilteredStudents] = useState<Student[]>()
  const [fetching, setFetching] = useState(false);
  const fetchRef = useRef(0);

  // Documents
  const [showDocument, setShowDocument] = useState(false);
  const [pdfViewerId, setPdfViewerId] = useState("");

  // show laptop fields
  const [visibleLaptopFields, setVisibleLaptopFields] = useState(false);

  // label and display if adult or not
  const [INETutorEstudiante, setINELabel] = useState("");
  const [letterDisplay, setLetterDisplay]  = useState("");
  const [isAdultStudent, setIsAdultStudent] = useState(false)

 //progress bar
 const [percent, setPercent] = useState<number>(0);
 const [fieldsForProgress, setFieldsForProgress] = useState<Agreement>(AgreementInit);

 // track changes
 const [filesChanges, setfilesChanges] = useState<any>({})

 // total hours
 const [totalHours, setTotalHours] = useState<string>("00:00");


  useEffect(() => {
    if (status === 'empty'){
      dispatch(pricipalDoList())
    }
  }, [status, dispatch])


  useEffect(() => {
    if (companyStateStatus !== 'fulfilled'){
      dispatch(doCompanyList());
    }
    if ( repsStateStatus !== `fulfilled` ){
      dispatch(repsDoList())
    }
  },[companyStateStatus, dispatch, repsStateStatus]);

  useEffect(() => {
    if (subsystemStateStatus !== "fulfilled") {
      dispatch(SubsystemList());
    }
    const optionsRaw: OptionsSelect[] = subsystems.map((subs) => ({
      label: `${subs.nameShort ?? " - "} `,
      value: subs.nameShort!,
      key: subs._id!,
    }));
    setOptionsSubsystem(optionsRaw);
  }, [dispatch, subsystemStateStatus, subsystems]);

   useEffect(() => {
    const principals = employees.filter((e) => typeof e.workcenter !== undefined).map((e) => ({
      label: e.name,
      value: e._id!,
      key: e._id!,
    }));
    setOptionsEmployee(principals)
  }, [employees]);

  useEffect(() => {
    getById(agreementId.id).then((agreementData) => {
      setAgreement(agreementData);
    })
  }, [agreementId]);

  useEffect(() => {
    if(filteredStudents && filteredStudents.length > 0){
       ifAdultChangeFiles(agreement?.studentId._id)
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [dispatch, agreement, filteredStudents])

  useEffect(() => {
    if (agreement) {
      setValueSubSystem(agreement.workCenterId ? agreement.workCenterId.subsystemShort : "");
      changeSubsystem(agreement.workCenterId ? agreement.workCenterId.subsystemShort : agreement.student?.workcenter.subsystemShort);
      handleWorkCenterChange(agreement.workCenterId ? agreement.workCenterId._id : agreement.student?.workcenter._id);
      setOptionStudents([
        {
          label: `${agreement.studentId?.name} ${agreement.studentId?.paternalLastname} ${agreement.studentId?.maternalLastname}`,
          value: agreement.studentId?._id!,
          key: agreement.studentId?._id!,
        },
      ])
      setOptionsCompany([
        {
          label: agreement.companyId?.name,
          value: agreement.companyId?._id!,
          key: agreement.companyId?._id!,
        },
      ]);
      if (agreement.legalRepresentativeId && agreement.legalRepresentativeId.name) {
        setOptionsCompanyLegalReps([
          {
            label: `${agreement.legalRepresentativeId?.name}`,
            value: agreement.legalRepresentativeId?._id!,
            key: agreement.legalRepresentativeId?._id!,
          },
        ]);
      } else if(agreement.companyId){
        setCompanyLegalReps(representatives.filter((r) => (r.companyId === agreement.companyId._id) && r.type === "Representative"))
      }
      if (agreement.representativeId && agreement.representativeId.name) {
        setOptionsCompanyContacts([
          {
            label: `${agreement.representativeId?.name}`,
            value: agreement.representativeId?._id!,
            key: agreement.representativeId?._id!,
          },
        ]);
      } else {
        handleCompanyChange(agreement.companyId._id)
      }
      agreementFormSetter(agreement, form)
      handleTimeChange(form,setTotalHours);
      setFieldsForProgress({
        sequence: agreement.sequence,
        studentId: agreement.studentId,
        workCenterId: agreement.workCenterId,
        companyId: agreement.companyId,
        representativeId: agreement.representativeId ?? null,
        legalRepresentativeId: agreement.legalRepresentativeId,
        startDate: agreement.startDate,
        endDate: agreement.endDate,
        status: agreement.status,
        learningAgreementFileId: agreement.learningAgreementFileId,
        colaborationAgreementFileId: agreement.colaborationAgreementFileId,
        tutorIdFileId: agreement.tutorIdFileId,
        tutorAuthLetterFileId: agreement.tutorAuthLetterFileId,
        insuranceFileId: agreement.insuranceFileId,
        rotationPlanFileId: agreement.rotationPlanFileId,
        learningPositionsFieldId: agreement.learningPositionsFieldId,
        matrixFieldId: agreement.matrixFieldId,
        principalFileId: agreement.principalFileId,
        principal: agreement.principal,
        principalPosition: agreement.principalPosition,
        rotationTimes: agreement.rotationTimes,
        rotationPositions: agreement.rotationPositions,
        hoursPerWeek: agreement.hoursPerWeek,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement]);

  useEffect(() => {
    showLaptopFields()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  useEffect(() => {
    CheckProgress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreement, fieldsForProgress, isAdultStudent]);

  //progress bar
  const CheckProgress = () => {
    let newProgress = percent;
    let numerator = Object.values(fieldsForProgress).filter(Boolean).length
    if (isAdultStudent && !agreement?.tutorAuthLetterFileId) {
      numerator++;
    }
    newProgress = Math.round(
      (numerator / Object.keys(fieldsForProgress).length) * 100
    );
    setPercent(newProgress);
  };

  const handleFormChange = (field: string, value: any, field2?: string, value2?: any) => {
    if(field2 !== undefined && value2 !== undefined) {
      setFieldsForProgress({ ...fieldsForProgress, [field]: value, [field2]: value2 });
    } else {
      setFieldsForProgress({ ...fieldsForProgress, [field]: value, });
    }
  };

  const handleTimeFieldsChange = (field: string, value: any) => {
    handleTimeChange(form,setTotalHours);
    handleFormChange(field, value)
  }

  const showLaptopFields = () => {
    let show = false
    if(!user){
      setVisibleLaptopFields(show);
      return false;
    }
    if (user.role && user.role === 1) {
      show= true
    }
    if(user.approvementRole && user.approvementRole?.sequence > 1) {
      show= true
    }
    setVisibleLaptopFields(show);
  }

  // files

  const onShowDocument = (id: string) => {
    setPdfViewerId(id);
    setShowDocument(true);
  };

  const onUpload = (field: string, id:string) => {
    form.setFieldValue(`${field}`, id);
    handleFormChange(field ?? "", id);
    setfilesChanges({ ...filesChanges, [field]: id });
  };
  const onUploadNoProgress = (field: string, id:string) => {
    form.setFieldValue(`${field}`, id);
    setfilesChanges({ ...filesChanges, [field]: id });
  };
  // Select Functions

  const handleSubmit = (values: any) => {
    let changedValues = form.getFieldsValue(true, meta => meta.touched);
    changedValues = {...changedValues, ...filesChanges}
    const sendPayload = {
      changedValues,
      agreement: values
    }
    dispatch(doPatch(sendPayload))
      .unwrap()
      .then(() => {
        notificationController.success({
          message: "Expediente editado correctamente",
        });
        setLoading(false);
        navigate(AGREEMENTS_PATH);
      })
      .catch((error) => {
        setLoading(false);
        notificationController.error({
          message: "Error al registrar, revisa los campos y las fechas. Revisa que el alumno y el plantel seleccionados coincidan",
        });
      });
  };

  // Students
  const debounceFetcher = useMemo(() => {
    const loadOptions = async (value: string) => {
      if(value === "") {
        return;
      }
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setFetching(true);
      const workc = form.getFieldValue("workCenterId");
      const foundStudents = await searchStudents({ searchParam: "fullName", searchValue: value });
      let resultStuents = foundStudents;
      if(workc && workc!== ""){
        resultStuents = foundStudents.filter((student) => student.workcenter._id === workc)
      }
      const newOptions = resultStuents.map((student) => ({
        label: `${student.name} ${student.paternalLastname ?? ""} ${student.maternalLastname ?? ""}`,
        value: student._id!,
        key: student._id!,
      }));
      if (fetchId !== fetchRef.current) {
        return;
      }
      let totalStudents = foundStudents;
      if(filteredStudents){
        totalStudents = [...filteredStudents, ...foundStudents];
      }
      setFilteredStudents(totalStudents);
      setOptionStudents(newOptions);
      setFetching(false);
    };

    return debounce(loadOptions, 400);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);


   const ifAdultChangeFiles = (newValue: any) => {
     handleFormChange("studentId", newValue);
     const studentSelected = filteredStudents!.find(
       (student) => student._id === newValue
     );
     if (!studentSelected) {
       return false;
     }
     let ineLabel = "INE del padre o tutor vigente";
     let display = "";
     let isAdultStudent = false;
     if (IsAdultStudent(studentSelected)) {
       ineLabel = "INE del estudiante mayor de edad";
       isAdultStudent = true;
     }
     if (isAdultStudent && !agreement?.tutorAuthLetterFileId) {
       display = "none";
     }
     setINELabel(ineLabel);
     setLetterDisplay(display);
     setIsAdultStudent(isAdultStudent);
   };

   const checkStudentWorkcMatch = () => {
    const studentId = form.getFieldValue("studentId");
    const workc = form.getFieldValue("workCenterId");
    if(studentId === agreement?.studentId._id && agreement!.studentId.workcenter._id === workc) {
      return Promise.resolve();
    }
    const studentSelected = filteredStudents!.find(
      (student) => student._id === studentId
    );
    if(!studentSelected || studentSelected.workcenter._id !== workc){
      return Promise.reject(new Error("El estudiante y el plantel no coinciden"));
    }
    // return true;
    return Promise.resolve();
   };

  //workcenter
  useEffect(() => {
    if(workcenterStateStatus !== 'fulfilled'){
      dispatch(doList())
    }
  }, [dispatch, workcenterStateStatus]);

  const handleWorkCenterChange = async (newValue: any) => {
    setOptionStudents([]);
    setFetching(true);
    const foundStudents = await searchStudents({ searchParam: "workCenterId", searchValue: newValue });
    let totalStudents = foundStudents;
    if(filteredStudents){
      totalStudents = [...filteredStudents, ...foundStudents];
    }
    setFilteredStudents(totalStudents);
    const newOptions = foundStudents.map((student) => ({
      label: `${student.name} ${student.paternalLastname ?? ""} ${student.maternalLastname ?? ""}`,
      value: student._id!,
      key: student._id!,
    }));
    setOptionStudents(newOptions);
    setFetching(false);
  };

  const filterWorkcenter = (workCenterName: string) => {
    let escapedWorkcName = escapeRegExp(workCenterName);
    let inputString= new RegExp(`${escapedWorkcName}`, 'gi');
    const newOptions = workcenters.filter(value => inputString.test(value.name)).map((workCenter) => ({
      label: workCenter.name!,
      value: workCenter._id!,
      key: workCenter._id!,
    }));;
    setOptionWorkCenter(newOptions);
  }

  const changeSubsystem = (subsystemSelected: any) => {
    const optionsRaw: OptionsSelect[] = workcenters
      .filter((workcenter) => workcenter.subsystemShort === subsystemSelected)
      .map((workCenter) => ({
        label: workCenter.name!,
        value: workCenter._id!,
        key: workCenter._id!,
      }));
    setOptionWorkCenter(optionsRaw);
    setValueSubSystem(subsystemSelected);
  };

  // Company

  useEffect(() => {
    const optionsRaw: OptionsSelect[] = companies.map((company) => ({
      label: company.name!,
      value: company._id!,
      key: company._id!,
    }));
    setOptionsCompany(optionsRaw);
  }, [companies]);

  useEffect(() => {
    const optionsRaw: OptionsSelect[] = companyContacts.map((rep) => ({
      label: `${rep.name}`,
      value: rep._id!,
      key: rep._id!,
    }));
    const optionsRawReps: OptionsSelect[] = companyLegalReps.map((rep) => ({
      label: `${rep.name}`,
      value: rep._id!,
      key: rep._id!,
    }));
    const selectedComp = form.getFieldValue('companyId');
    if(agreement?.companyId._id !== selectedComp) {
      handleFormChange('representativeId', '', 'legalRepresentativeId', '')
    }
    setOptionsCompanyContacts(optionsRaw);
    setOptionsCompanyLegalReps(optionsRawReps);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyContacts, companyLegalReps]);

  const handleCompanyChange = (value: any) => {
    if(agreement?.companyId !== value) {
      form.setFieldValue('representativeId', null)
      form.setFieldValue('legalRepresentativeId', null)
    }
    handleFormChange('companyId', value)
    setCompanyContacts(representatives.filter((r) => (r.companyId === value) && r.type === "Contact"));
    setCompanyLegalReps(representatives.filter((r) => (r.companyId === value) && r.type === "Representative"))
  };

  const showAllCompanyOptions = () => {
    if(optionsCompany.length <= 1) {
      const newOptions = companies.map((company) => ({
      label: company.name!,
      value: company._id!,
      key: company._id!,
    }));;
    setOptionsCompany(newOptions);
    }
  }

  const filterCompany = (companyName: string) => {
    let escapedCompanyName = escapeRegExp(companyName);
    let inputString= new RegExp(`${escapedCompanyName}`, 'gi');
    const newOptions = companies.filter(value => inputString.test(value.name)).map((company) => ({
      label: company.name!,
      value: company._id!,
      key: company._id!,
    }));;
    setOptionsCompany(newOptions);
  }

  return (
    <>
      {agreement ? (
        <S.Wrapper>
          <S.SmallCard
            id="agreement-edit"
            title={
              <>
                <CloseButton onClick={()=>{navigate(AGREEMENTS_PATH)}}></CloseButton>
                <span>&nbsp; {`Editar Expediente`}</span>
              </>
            }
            padding="3rem"
          >
          <Tooltip title={percent === 100 ? "Campos completos" : "Campos incompletos"}>
            <Progress percent={percent} />
          </Tooltip>
            <BaseForm
              layout="vertical"
              form={form}
              onFinish={handleSubmit}
              requiredMark="optional"
              initialValues={initialValues}
            >
              <BaseForm.Item name="id" hidden />
              {/* Plantel */}
              <Row>
                <Col style={{ marginRight: 25 }}>
                  <BaseForm.Item requiredMark={false} label={`Subsistema`}>
                    <Select
                      style={{ width: 200 }}
                      value={valueSubSystem}
                      onChange={changeSubsystem}
                      placeholder="Sub-Sistema"
                      options={optionsSubsystem}
                    />
                  </BaseForm.Item>
                </Col>
                <Col flex={1}>
                  <BaseForm.Item
                    name="workCenterId"
                    label={`Plantel`}
                    dependencies={['studentId']}
                    rules={[
                      { required: true, message: "Este campo es obligatorio" },
                      { validator: checkStudentWorkcMatch },
                    ]}
                  >
                    <Select
                      style={{ width: "100%" }}
                      showSearch
                      options={optionWorkCenter}
                      onSearch={filterWorkcenter}
                      onChange={handleWorkCenterChange}
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        (option?.label ?? "")
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                    />
                  </BaseForm.Item>
                </Col>
              </Row>
              <Row>
                <Col flex={1}>
                <BaseForm.Item
                  name="principal"
                  label="Director(a) del Plantel Firmante"
                  rules={[{ required: true, message: "Este campo es obligatorio" }]}
                >
                  <Select
                    showSearch
                    placeholder="Director(a) del plantel"
                    onChange={(e) => handleFormChange('principal', e)}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={optionsEmployee}
                  />
                </BaseForm.Item>
                </Col>
                <Col flex={1} style={{ marginLeft: 20 }}>
                <BaseForm.Item
                  name="principalPosition"
                  label="Puesto del director(a) firmante"
                >
                  <Input
                    onChange={(e) => handleFormChange('principalPosition', e.target.value)}
                    placeholder="Encargado(a) de la Dirección del Plantel"
                  />
                </BaseForm.Item>
                </Col>
              </Row>
              {/* Student Data  */}
              <BaseForm.Item
                name="studentId"
                label={`Alumno`}
                rules={[
                  { required: true, message: "Este campo es obligatorio" },
                ]}
              >
               <Select
                  showSearch
                  onSearch={debounceFetcher}
                  placeholder='Busca al alumno por nombre'
                  notFoundContent={fetching ? <Spin size='small' /> : null}
                  onChange={ifAdultChangeFiles}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={optionStudents}
                />
              </BaseForm.Item>
              {/* Company Data  */}
              <Row>
                <Col flex={3} style={{ marginRight: 25 }}>
                  <BaseForm.Item label={`Razón social`} required>
                    <Row>
                      <Col style={{ marginRight: 10 }} flex={1}>
                        {/* <Debounce /> */}
                        <BaseForm.Item
                          name="companyId"
                          rules={[
                            {
                              required: true,
                              message: "Este campo es obligatorio",
                            },
                          ]}
                        >
                          <Select
                            showSearch
                            onSearch={filterCompany}
                            onDropdownVisibleChange={showAllCompanyOptions}
                            onChange={(e)=>handleCompanyChange(e)}
                            placeholder="Selecciona un Empresa"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            style={{ minWidth: 350 }}
                            options={optionsCompany}
                          />
                        </BaseForm.Item>
                      </Col>
                    </Row>
                  </BaseForm.Item>
                </Col>
                <Col flex={2} style={{ marginRight: 10 }} >
                  <BaseForm.Item
                    name="representativeId"
                    label={`Contacto de la Empresa`}
                    rules={[
                      { required: true, message: "Este campo es obligatorio" },
                    ]}
                  >
                    <Select
                      key="CompanyContactSelect"
                      value={companyContacts}
                      options={optionsCompanyContacts}
                      onChange={(e) => handleFormChange('representativeId', e)}
                    />
                  </BaseForm.Item>
                </Col>
                <Col flex={1}>
                  <BaseForm.Item
                    name="legalRepresentativeId"
                    label={`Representante legal de la Empresa`}
                  >
                    <Select
                      key="CompanyRepSelect"
                      value={companyLegalReps}
                      options={optionsCompanyLegalReps}
                      onChange={(e) => handleFormChange('legalRepresentativeId', e)}
                    />
                  </BaseForm.Item>
                </Col>
              </Row>
              <TimeFields handleTimeFieldsChange={handleTimeFieldsChange} totalHours={totalHours}/>
              {visibleLaptopFields && (
                <LaptopFields
                  onUpload={onUploadNoProgress}
                  onShowDocument={onShowDocument}
                  form={form}
                />
              )}
              <Row>
              {agreement.status === "INACTIVO" && (
                  <InnactiveLetterField
                    form={form}
                    onShowDocument={onShowDocument}
                    onUpload={onUploadNoProgress}
                  />
                )}
                <Col>
                  <Divider orientation="left">Convenio de Colaboración</Divider>
                  {fileList(getColabFiles(agreement), onShowDocument, onUpload, form)}
                </Col>
                <Col>
                <Divider orientation="left">Convenio de Aprendizaje</Divider>
                {fileList(
                    getFilesLearningAgreement(agreement, letterDisplay, INETutorEstudiante),
                    onShowDocument,
                    onUpload,
                    form
                  )}
                  <RotationFields onChange={handleFormChange}/>
                </Col>
              </Row>
              <BaseForm.Item noStyle>
                <S.SubmitButton
                  type="primary"
                  htmlType="submit"
                  size="large"
                  loading={isLoading}
                >
                  {`Actualizar`}
                </S.SubmitButton>
              </BaseForm.Item>
            </BaseForm>
          </S.SmallCard>

          <Document
            fileId={pdfViewerId}
            open={showDocument}
            onCancel={() => {
              setShowDocument(false)
              setPdfViewerId("");
            }}
            onOk={() => {
              setShowDocument(false)
              setPdfViewerId("");
            }}
          />
        </S.Wrapper>
      ):
      (
        <>
        <S.Wrapper
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spin tip="Cargando" size="large" delay={500}></Spin>
        </S.Wrapper>
      </>
      )}
      {!agreement && <div>Cargando Expediente</div>}
    </>
  );
};
