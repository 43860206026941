import {
  Employee,
  initialValues as initEmployee,
} from "components/employees/types";

export type WorkCenter = {
  _id?: string;
  name: string;
  nameShort: string;
  principal: Employee;
  keySep?: string; // mts
  subsystem: string; // typeof subsystems[number];
  subsystemShort: string;
  currentSubsystemPrincipal?: string;
};

export const initialValues: WorkCenter = {
  name: "",
  nameShort: "",
  principal: initEmployee,
  subsystem: '',
  subsystemShort: "",
  currentSubsystemPrincipal: "",
};
