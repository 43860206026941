import { Avatar, List, Drawer } from "antd";
import { Agreement, initialValues } from "components/Agreements/types";
import { AgreementDetail } from "components/Agreements/AgreementDetail";
import { OptionsDrawerBar } from "components/Agreements/details/OptionsDrawerBar";
import React, { useState } from "react";
import { colorCompletedHours, colorStatus } from "components/Agreements/Agreements.styles";
import { getHoursMinutesString } from "hooks/dateTreatment";
import { estimateTotalHours } from "components/Agreements/utils/utils";

type Props = {
  agreements: Agreement[];
};

export const AgreementsList = ({ agreements }: Props) => {
  const [selectedRowData, setSelectedRowData] = useState<Agreement>();
  const [open, setOpen] = useState(false);

  // actual hours estimate
  const getActualHoursEstimate = (item: Agreement) => {
    if (!item.hoursPerWeek || !item.startDate) {
      return { hours: 0, minutes: 0 };
    }
    const today = new Date();
    today.setHours(0, 0, 0);
    const hoursMin = estimateTotalHours(
      item.startDate,
      today,
      item.hoursPerWeek
    );
    if (hoursMin !== false) {
      // setHoursEstimate(hoursMin);
      return hoursMin;
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onCloseDrawer = () => {
    setSelectedRowData(initialValues);
    setOpen(false);
  };

  const getStatusLights = (status: string) => {
    switch (status) {
      case "ACTIVO":
        return  "#329213" ;
      case "INACTIVO":
        return "#555859";
        case "BORRADOR":
        return  "#b36d19";
      case "PENDIENTE DE REVISION":
        return  "#FF8C00";
      case "RECHAZADO":
        return  "#cc002e"
      default:
        return "#b36d19"
    }
  };

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={agreements}
        pagination={{
          pageSize: 3,
          total: agreements.length,
          pageSizeOptions: ["1", "2", "3"],
        }}
        renderItem={(item) => (
          <List.Item
            title={item.status}
            key={item._id}
            onClick={() => {
              setSelectedRowData(item);
              showDrawer();
            }}
          >
            <List.Item.Meta
              avatar={
                <Avatar
                  style={{
                    backgroundColor: getStatusLights(item.status),
                    verticalAlign: "middle",
                  }}
                  size="large"
                >
                  {item.sequence}
                </Avatar>
              }
              title={`${
                item.studentId
                  ? `${item.studentId.name} ${
                      item.studentId.paternalLastname ?? ""
                    } ${item.studentId.maternalLastname ?? ""}`
                  : "Alumno no encontrado"
              } - Empresa: ${
                item.companyId ? item.companyId.name : "Empresa no encontrada"
              }`}
              description={
                <div>
                  <p>
                    <span style={colorStatus(item.status)}>{item.status} </span>
                    {item.status === "INACTIVO" && (
                      <span>{` - Fecha de baja: ${
                        item.inactiveDate ?? "sin registro"
                      }`}</span>
                    )}
                  </p>
                  {item.status === "INACTIVO" ? (
                    <p>
                      Horas completadas:{" "}
                      <span
                        style={colorCompletedHours( item.completedHours ? item.completedHours.hours : 0)}
                      >
                        { item.completedHours ? getHoursMinutesString(item.completedHours) : "Sin registro"}
                      </span>
                    </p>
                  ): (
                    item.startDate &&
                    item.hoursPerWeek && (
                      <p>
                        Horas Actuales:{" "}
                         <span
                          style={colorCompletedHours(
                            getActualHoursEstimate(item)!.hours
                          )}
                        >
                          {getActualHoursEstimate(item)
                            ? getHoursMinutesString(getActualHoursEstimate(item) ?? false)
                            : "Sin registro"}
                        </span>
                      </p>
                    )
                  )}
                  <p>{`Correo: ${
                    item.studentId ? item.studentId.email : "No encontrado"
                  }`}</p>
                  <p>{`Plantel: ${
                    item.studentId
                      ? item.studentId.workcenter?.nameShort
                      : "No encontrado"
                  }`}</p>
                  <p>{`Representante: ${
                    item.representativeId
                      ? item.representativeId.name
                      : "No encontrado"
                  }`}</p>
                </div>
              }
            />
          </List.Item>
        )}
      ></List>

      <Drawer
        title="Detalles del expediente Dual"
        placement="right"
        width="50%"
        onClose={onCloseDrawer}
        destroyOnClose
        open={open}
        extra={
          <OptionsDrawerBar
            rowSelected={selectedRowData ?? initialValues}
            closeDrawer={setOpen}
          />
        }
      >
        <AgreementDetail
          selectedRow={selectedRowData ?? initialValues}
          closeModalDetails={false}
        />
      </Drawer>
    </>
  );
};
