import {FormInstance } from "antd";
import { ShowDocumentBtn } from "components/common/Document/ShowDocumentBtn";
import { AWSFileUploader } from "components/common/FileManager/AWSFileUploader";
import { BaseForm } from "components/common/forms/BaseForm/BaseForm";

interface innactiveLetterProps {
  onShowDocument: (id: string) => any;
  onUpload: (field: string, id: string) => any;
  form: FormInstance;
}

export const InnactiveLetterField = ({
  form,
  onShowDocument,
  onUpload,
}: innactiveLetterProps) => (
  <BaseForm.Item
  name="inactiveLetterFileId"
  label="Carta de baja de expediente"
  key="inactiveLetterFileId"
>
  {form.getFieldValue("inactiveLetterFileId") ? (
    <>
      <ShowDocumentBtn
        onClick={() => {
          onShowDocument(form.getFieldValue("inactiveLetterFileId"));
        }}
      />
      <br />
    </>
  ) : (
    "No hay Carta de baja de expediente "
  )}
  <AWSFileUploader
    title="Carta de baja de expediente"
    bucket="inactive-letter"
    onUploadCompleted={(id: string[]) => {
      onUpload("inactiveLetterFileId", id[0])
    }}
    once
  />
</BaseForm.Item>
);
