import React from "react";
import loadable from "@loadable/component";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainLayout from "../layouts/main/MainLayout/MainLayout";
import RequireAuth from "./RequireAuth";

import DashboardPage from "pages/DashboardPage";
import EmployeeNewPage from "pages/EmployeeNewPage";
import EmployeeEditPage from "pages/EmployeesEditPage";
import EmployeesPage from "pages/EmployeesPage";
import ForgotPasswordPage from "pages/ForgotPasswordPage";
import LoginPage from "pages/LoginPage";
import LockPage from "pages/LockPage";
import NewPasswordPage from "pages/NewPasswordPage";
import ConfirmUserPage from "pages/ConfirmUserPage";
import WorkCenterListPage from "pages/workcenters/WorkCenterListPage";
import WorkCenterNewPage from "pages/workcenters/WorkCenterNewPage";
import StudentsPage from "pages/Students/StudentPage";
import StudentNewPage from "pages/Students/StudentNewPage";
import StudentEditPage from "pages/Students/StudentEditPage";
import CompanyPage from "pages/CompanyPage";
import CompanyEditPage from "pages/CompanyEditPage";
import AgreementsListPage from "pages/Agreements/AgreementsListPage";
import AgreementNewPage from "pages/Agreements/AgreementNewPage";
import LogOut from "../../pages/LogOut";
import AgreementEditPage from "pages/Agreements/AgreementEdit";
import SubsystemListPage from "pages/subsystems/SubsystemListPage";
import SubsystemNewPage from "pages/subsystems/SubsystemNewPage";
import SubsystemEditPage from "pages/subsystems/SubsystemEditPage";
import WorkcenterEditPage from "pages/workcenters/WorkcenterEditPage";
import FollowPage from "pages/Follow/FollowPage";
import { RequirePermission } from "pages/ValidateAccess/CheckUserPermissions";
import { NoAccess } from "pages/ValidateAccess/NoAccess";
import {PermissionsToAccess} from "checkAccess/ConstPermissions"

const {
  readAgreements,
  writeAgreements,
  readCompanies,
  writeCompanies,
  readEmployees,
  writeEmployees,
  readStudents,
  writeStudents,
  readSubsystems,
  writeSubsystems,
  readWorkcenters,
  writeWorkcenters,
  editApprovedAgreements
} = PermissionsToAccess

export const DASHBOARD_PATH = "/";
export const EMPLOYEES_PATH = "/colaboradores";
export const AGREEMENTS_PATH = "/expedientes";
export const FOLLOW_PATH = "/seguimiento";
export const STUDENTS_PATH = "/alumnos";
export const COMPANY_PATH = "/empresas";
export const WORKCENTERS_PATH = "/planteles";
export const SUBSYSTEMS_PATH = "/subsistemas";
export const NOACCESS_PATH = "/sinacceso";
const AuthLayout = loadable(() => import("../layouts/AuthLayout/AuthLayout"));

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );
  return (
    <BrowserRouter>
      <Routes>
        <Route path={DASHBOARD_PATH} element={protectedLayout}>
          <Route index element={<DashboardPage />} />
        </Route>
        <Route path={EMPLOYEES_PATH} element={protectedLayout}>
          <Route
            index
            element={ <RequirePermission children={<EmployeesPage />} accRequest={readEmployees}/> }
          />
          <Route
            path="nuevo"
            element={ <RequirePermission children={<EmployeeNewPage />}  accRequest={writeEmployees}/> }
          />
          <Route
            path="editar/:id"
            element={ <RequirePermission children={<EmployeeEditPage />} accRequest={writeEmployees}/> }
          />
        </Route>
        <Route path={WORKCENTERS_PATH} element={protectedLayout}>
          <Route
            index
            element={ <RequirePermission children={<WorkCenterListPage />} accRequest={readWorkcenters}/> }
          />
          <Route
            path="nuevo"
            element={ <RequirePermission children={<WorkCenterNewPage />} accRequest={writeWorkcenters}/> }
          />
          <Route
            path="editar/:id"
            element={ <RequirePermission children={<WorkcenterEditPage />} accRequest={writeWorkcenters}/> }
          />
        </Route>
        <Route path={SUBSYSTEMS_PATH} element={protectedLayout}>
          <Route
            index
            element={ <RequirePermission children={<SubsystemListPage />} accRequest={readSubsystems}/> }
          />
          <Route
            path="nuevo"
            element={ <RequirePermission children={<SubsystemNewPage />} accRequest={writeSubsystems}/> }
          />
          <Route
            path="editar/:id"
            element={ <RequirePermission children={<SubsystemEditPage />} accRequest={writeSubsystems}/> }
          />
        </Route>
        <Route path={STUDENTS_PATH} element={protectedLayout}>
          <Route
            index
            element={ <RequirePermission children={<StudentsPage />} accRequest={readStudents}/> }
          />
          <Route
            path="nuevo"
            element={ <RequirePermission children={<StudentNewPage />} accRequest={writeStudents}/> }
          />
          <Route
            path="edit/:id"
            element={ <RequirePermission children={<StudentEditPage />} accRequest={writeStudents}/> }
          />
        </Route>
        <Route path={COMPANY_PATH} element={protectedLayout}>
          <Route
            index
            element={ <RequirePermission children={<CompanyPage />} accRequest={readCompanies}/> }
          />
          <Route
            path="editar/:id"
            element={ <RequirePermission children={<CompanyEditPage />} accRequest={writeCompanies}/> }
          />
        </Route>
        <Route path={AGREEMENTS_PATH} element={protectedLayout}>
          <Route
            index
            element={ <RequirePermission children={<AgreementsListPage />} accRequest={readAgreements}/> }
          />
           <Route
            path="bajas"
            element={ <RequirePermission children={<AgreementsListPage filter="inactiveAgreements" />} accRequest={readAgreements}/> }
          />
          <Route
            path="nuevo"
            element={ <RequirePermission children={<AgreementNewPage />} accRequest={writeAgreements}/> }
          />
          <Route
            path="edit/:id"
            element={ <RequirePermission children={<AgreementEditPage />}accRequest={writeAgreements}/> }
          />
          <Route
            path="editapproved/:id"
            element={ <RequirePermission children={<AgreementEditPage />}accRequest={editApprovedAgreements}/> }
          />
        </Route>
        <Route path={FOLLOW_PATH} element={protectedLayout}>
          <Route
            index
            element={ <RequirePermission children={<FollowPage />} accRequest={readAgreements}/> }
          />
        </Route>
        <Route path={NOACCESS_PATH} element={protectedLayout}>
          <Route index element={<NoAccess />} />
        </Route>
        <Route path="/auth" element={<AuthLayout />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="logout" element={<LogOut />} />
          <Route path="confirm/:token" element={<ConfirmUserPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
